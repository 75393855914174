import { createContext, useState, useContext, ReactElement } from 'react';
import {
  NotificationProps,
  Notifications,
} from 'src/components/Layout/Notification/Notification';
export type NotificationsContextProps = {
  notifications: NotificationProps[];
  addNotification: (arg: NotificationProps) => any;
  removeNotification: (timestamp: number) => any;
};
export const NotificationsContext = createContext<NotificationsContextProps>({
  notifications: [],
  addNotification: (arg: NotificationProps) => {},
  removeNotification: (timestamp: number) => {},
});
export const NotificationsContextProvider = NotificationsContext.Provider;
export const useNotificationsContext = () =>
  useContext<NotificationsContextProps>(NotificationsContext);

export const NotificationsProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  const addNotification = (ntfctn: NotificationProps) =>
    setNotifications((prevState) => [
      ...prevState.filter((n) => n.timestamp !== ntfctn.timestamp),
      ntfctn,
    ]);

  const removeNotification = (timestamp: number) =>
    setNotifications((prevState) =>
      [...prevState].filter((ntfctn) => ntfctn.timestamp !== timestamp)
    );

  return (
    <NotificationsContextProvider
      value={{
        notifications,
        addNotification: (post) => addNotification(post),
        removeNotification: (timestamp) => removeNotification(timestamp),
      }}
    >
      {children}
      <Notifications ns={notifications} />
    </NotificationsContextProvider>
  );
};
