import { faBadgePercent } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function DiscountBlock() {
  return (
    <div className="tw-overflow-hidden tw-absolute tw-z-0 tw-inset-0 tw-animate-pulse">
      <FontAwesomeIcon
        icon={faBadgePercent}
        className="tw-text-7xl tw-text-horny-red-400 tw-opacity-35 tw-absolute"
        style={{
          transform: 'scale(4)',
          transformOrigin: 'top right',
          right: '-10%',
          top: '-10%',
          zIndex: -1,
        }}
      />
    </div>
  );
}
