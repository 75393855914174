import { AxiosError } from 'axios';

export function GetAxiosError(e: AxiosError, defaultMessage: string) {
  if (
    e.response &&
    'data' in e.response &&
    typeof e.response.data === 'object' &&
    e.response.data !== null &&
    'message' in e.response.data &&
    typeof e.response.data.message === 'string'
  ) {
    return e.response.data.message;
  } else return defaultMessage;
}
