import { Slider } from '@ark-ui/react';

type Enumerate<
  N extends number,
  Acc extends number[] = [],
> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

type IntRange<F extends number, T extends number> =
  | Exclude<Enumerate<T>, Enumerate<F>>
  | T;

export type ArkSliderValueType = IntRange<0, 100>;

type ArkSliderProps = {
  value: ArkSliderValueType;
  onChange: (value: ArkSliderValueType) => void;
};
export default function ArkSlider({ value, onChange }: ArkSliderProps) {
  return (
    <Slider.Root
      orientation="vertical"
      className="tw-w-4 tw-h-full"
      onValueChange={(value) => onChange(value.value[0] as ArkSliderValueType)}
      value={[value]}
      min={0}
      max={100}
      step={1}
    >
      {/* <Slider.Label>Label</Slider.Label> */}
      {/* <Slider.ValueText /> */}
      <Slider.Control className="tw-w-4 tw-flex tw-items-center tw-justify-center tw-h-full">
        <Slider.Track className="tw-bg-horny-gray-900 tw-w-2 tw-h-full tw-rounded-full tw-overflow-hidden tw-border-[0.5px] tw-border-horny-gray-100 tw-border-opacity-25">
          <Slider.Range className="tw-bg-horny-gray-300 tw-w-2" />
        </Slider.Track>
        <Slider.Thumb
          index={0}
          className="tw-w-4 tw-h-4 tw-bg-horny-gray-100 tw-rounded-full"
        >
          <Slider.HiddenInput />
        </Slider.Thumb>
      </Slider.Control>
    </Slider.Root>
  );
}
