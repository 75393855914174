import {
  faArrowRight,
  faCircleCheck,
  faSpinnerThird,
  faWarning,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ChatOtherSettings,
  UpdateChatOtherSettingsRequest,
} from '@temptations-ai/types';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import { ChatAPI } from '../../services/API/ChatAPI';
import { cn } from '../../shared/utils';
import { useVoices } from '../Audio/UseVoices';
import VoiceSwitcher from '../Audio/VoiceSwitcher';
import { showToast } from '../Shared/Ark/ArkToast';
import { TemptationsButton } from '../Shared/TemptationsButton';

type ChatSettingsPorops = {
  botId: string;
  settings: ChatOtherSettings;
  onApply: () => void;
};

type ApplyState = 'initial' | 'loading' | 'success';

export function ChatSettings({ botId, settings, onApply }: ChatSettingsPorops) {
  const { t } = useTranslation();
  const voices = useVoices();
  const queryClient = useQueryClient();
  const initialSettings =
    settings.voice_id !== null
      ? settings
      : { ...settings, voice_id: voices[0].value.toString() };
  const [currentSettings, setCurrentSettings] =
    useState<ChatOtherSettings>(initialSettings);

  const [hasVoice, setHasVoice] = useState(settings.voice_id ? true : false);

  const [applyState, setApplyState] = useState<ApplyState>('initial');

  const updateChatOtherSettingsMutation = useMutation({
    mutationFn: (data: UpdateChatOtherSettingsRequest) =>
      ChatAPI.updateChatOtherSettings(data),
    onMutate: () => {
      setApplyState('loading');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['chatOtherSettings', botId],
        exact: false,
        refetchType: 'all',
      });
      setHasVoice(true);
      setApplyState('success');
      setTimeout(() => {
        setApplyState('initial');
        onApply();
      }, 1000);
    },
    onError: () => {
      showToast({
        title: t('Error updating chat settings'),
        description: t('An error occured while updating chat settings'),
        support: true,
      });
    },
  });

  return (
    <motion.div
      className="tw-h-full tw-flex tw-items-center tw-px-2"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      {/* text-center  */}
      <div className="tw-self-center tw-px-1 tw-w-full">
        <div className="tw-p-6 tw-flex tw-flex-col tw-gap-4 tw-bg-horny-gray-600  tw-rounded-xl tw-shadow-bezel tw-w-full">
          <div className="tw-text-2xl tw-font-bold">{t('Chat Settings')}</div>
          <div className="tw-flex tw-flex-col tw-gap-2">
            <div className="tw-text-xl tw-font-bold">
              {t('Character Voice')}
            </div>
            <div>{t('Voice settings description')}</div>
            <VoiceSwitcher
              voices={voices}
              defaultVoice={
                settings.voice_id
                  ? settings.voice_id
                  : voices[0].value.toString()
              }
              onChange={(value) => {
                setCurrentSettings((prev) => ({
                  ...prev,
                  voice_id: value,
                }));
              }}
            />
            {!hasVoice && (
              <div className="tw-text-horny-orange-300 tw-font-bold tw-px-4 tw-py-3 tw-bg-black tw-bg-opacity-50 tw-rounded-md tw-flex tw-flex-row tw-gap-4 tw-items-center tw-justify-center">
                <FontAwesomeIcon icon={faWarning} />
                {t('no_voice_error')}
              </div>
            )}
          </div>
          <div className="tw-w-full tw-flex tw-justify-center tw-relative">
            <TemptationsButton
              color="red"
              size="base"
              onClick={() => {
                updateChatOtherSettingsMutation.mutate({
                  BotId: botId,
                  ChatOtherSettings: currentSettings,
                });
              }}
            >
              <AnimatePresence mode="wait">
                <motion.div
                  className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-justify-center  tw-min-w-44"
                  key={applyState}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                >
                  <FontAwesomeIcon
                    icon={
                      applyState === 'loading'
                        ? faSpinnerThird
                        : applyState === 'success'
                          ? faCircleCheck
                          : applyState === 'initial'
                            ? faArrowRight
                            : faArrowRight
                    }
                    className={cn(
                      applyState === 'loading' && 'tw-animate-spin'
                    )}
                  />
                  <div className="tw-text-nowrap tw-text-center">
                    {applyState === 'loading'
                      ? t('Wait...')
                      : applyState === 'success'
                        ? t('Success!')
                        : applyState === 'initial'
                          ? t('Apply Settings')
                          : t('Apply Settings')}
                  </div>
                </motion.div>
              </AnimatePresence>
            </TemptationsButton>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
