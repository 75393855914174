import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'src/shared/utils';

export type Tab = {
  value: string;
  icon: IconDefinition;
};

type ExtendedTab = Tab & { onClick: () => void; isSelected: boolean };
function Tab({ value, isSelected, icon, onClick }: ExtendedTab) {
  const { t } = useTranslation();
  return (
    <motion.div
      className={cn(
        'tw-flex tw-flex-row tw-gap-2 tw-items-center',
        isSelected
          ? 'tw-pointer-events-none'
          : 'tw-opacity-75 tw-cursor-pointer'
      )}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} />
      <div
        className={cn(
          'tw-font-bold',
          isSelected && 'tw-underline tw-underline-offset-2'
        )}
      >
        {t(value)}
      </div>
    </motion.div>
  );
}

type ArkTabsProps = {
  tabs: Tab[];
  defaultTabValue: string;
  onTabChange: (tabValue: string) => void;
};
export default function ArkTabs({
  tabs,
  defaultTabValue,
  onTabChange,
}: ArkTabsProps) {
  const [selectedTab, setSelectedTab] = useState(defaultTabValue);
  return (
    <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center tw-justify-center tw-px-3 tw-py-2 tw-bg-horny-gray-900 tw-border tw-border-horny-gray-600 tw-rounded-md tw-shadow tw-w-min">
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          isSelected={tab.value === selectedTab}
          icon={tab.icon}
          onClick={() => {
            setSelectedTab(tab.value);
            onTabChange(tab.value);
          }}
        />
      ))}
    </div>
  );
}
