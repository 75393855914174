import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import {
  addClickMagicPurchase,
  addClickMagicPurchaseUpgrade,
  upgradePricesYear,
  prices as monthPricesSub,
  pricesYear,
  upgradePrices,
} from 'src/context/AnalyticsContext/helpersDOM';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { UserAPI, UserAPIModel } from 'src/services/API/UserAPI';
import {
  SubscriptionProviderFlowProps,
  SubscriptionProviderFlowStage,
} from './SubscriptionProviderFlow';
import { SubscriptionLevel } from '../../../services/API/ResourcesAPI';

export function G2AProviderFlow(
  props: Omit<SubscriptionProviderFlowProps, 'subscriptionLevel'> & {
    subscriptionLevel: string;
  }
) {
  const { t } = useTranslation();
  const storedSelected = useMemo(() => {
    return props.selectedProvider;
  }, [props.selectedProvider]);
  const [stage, setStage] = useState<SubscriptionProviderFlowStage>(
    props.selectedProvider
      ? SubscriptionProviderFlowStage.ReadyToUpdate
      : SubscriptionProviderFlowStage.Init
  );
  const [refreshing, setRefreshing] = useState(false);
  const { setUser, user } = useUserContext();
  const { capture } = useAnalyticsContext();
  const emailRef = useRef<HTMLSpanElement>(null);
  const { prices } = useResourcesContext();

  useEffect(() => {
    if (
      props.selectedProvider === true &&
      !storedSelected &&
      stage === SubscriptionProviderFlowStage.StartedPayment
    ) {
      setStage(SubscriptionProviderFlowStage.Init);
    }
  }, [props.selectedProvider]);

  const refresh = () => {
    if (refreshing) {
      return false;
    }
    setRefreshing(true);
    const storedUser = JSON.parse(JSON.stringify({ ...user })) as UserAPIModel;
    UserAPI.updateBoostyUser()
      .then(() =>
        UserAPI.get().then(async ({ data }) => {
          if (!storedUser?.Subscription.Level && data.Subscription.Level) {
            await addClickMagicPurchase(
              data.Subscription.Level,
              data.Subscription.IsYearly
            ).catch(console.error);

            //captureSubscriptionByTrafficJunky(data.Subscription.Level);
          }
          if (
            data.Subscription.Level !== storedUser?.Subscription.Level &&
            !!data.Subscription.Level
          ) {
            // capture({
            //   event: 'subscribe_process',
            //   data: {
            //     state: 'Boosty_verified',
            //     level: props.subscriptionLevel,
            //     income: prices!.Subscriptions[props.subscriptionLevel].Price,
            //   },
            // });
            // UserAPI.sendRevenue(
            //   prices!.Subscriptions[props.subscriptionLevel].Price
            // );
            // await addTrafficJunkyTag(
            //   prices!.Subscriptions[props.subscriptionLevel].Price,
            //   props.subscriptionLevel
            // );
            setUser(data);
          }
        })
      )
      .finally(() => {
        setStage(SubscriptionProviderFlowStage.ReadyToUpdate);
        setRefreshing(false);
      });
  };

  const buyG2AProduct = () => {
    if (refreshing || !emailRef.current || !emailRef.current.innerText) {
      return;
    }
    setRefreshing(true);
    const storedUser = JSON.parse(JSON.stringify({ ...user })) as UserAPIModel;

    UserAPI.buyProductG2A(emailRef.current.innerText)
      .then(({ data: purchaseData }) =>
        UserAPI.get().then(async ({ data }) => {
          if (!storedUser?.Subscription.Level && data.Subscription.Level) {
            await addClickMagicPurchase(
              data.Subscription.Level,
              data.Subscription.IsYearly
            ).catch(console.error);

            //captureSubscriptionByTrafficJunky(data.Subscription.Level);
          }

          // const isUpgrade = upgradePrices[props.subscriptionLevel];
          // const newUpgrade = data.Upgrades.find(
          //   ({ Title }) => Title === props.subscriptionLevel
          // );
          const upgradeAdded = data.Upgrades.find(({Title}) => !storedUser.Upgrades.find(({Title: oldUpTitle}) => oldUpTitle === Title));
          const upgradeUpdated = data.Upgrades.find(({Title, EndDate}) => {
            const oldUp = storedUser.Upgrades.find(({Title: oldUpTitle, EndDate: oldUpEndDate}) => oldUpTitle === Title);
            return oldUp && new Date(EndDate).getTime() > new Date(oldUp.EndDate).getTime();
          });

          const newUpgrade = upgradeAdded || upgradeUpdated;

          console.log({
            upgradeAdded,
            upgradeUpdated,
            newUpgrade,
            level: props.subscriptionLevel,
          });
          if (newUpgrade) {
            await addClickMagicPurchaseUpgrade(
              newUpgrade.Title,
              newUpgrade.IsYearly
            ).catch(console.error);
          }
          // const oldUpgrade = storedUser.Upgrades.find(({Title}) => Title === props.subscriptionLevel);
          // const newUpgrade = data.Upgrades.find(({Title}) => Title === props.subscriptionLevel);
          // if ((!oldUpgrade && newUpgrade) || (oldUpgrade && newUpgrade && newUpgrade.EndDate > oldUpgrade.EndDate)){
          //   await addClickMagicPurchaseUpgrade(newUpgrade.Title, newUpgrade.IsYearly).catch(
          //     console.error
          //   );
          // }
          setUser(data);
        })
      )
      .finally(() => setRefreshing(false));
  };

  const stageElement = {
    [SubscriptionProviderFlowStage.Init]: (
      <>
        <HornyButton
          data-anal="subscribe_process"
          data-anal-state="Click_g2a"
          data-anal-level={props.subscriptionLevel}
          onClick={() => {
            window.open(
              `https://www.g2a.com/${props.subscriptionId}`,
              '_blank'
            );
            capture({
              event: 'subscribe_process',
              data: {
                state: 'G2A_click',
                level: props.subscriptionLevel,
                income: (() => {
                  // не повторяйте это дома
                  let price =
                    props.subscriptionLevel.endsWith('Up') &&
                    (props.isYearly ? upgradePricesYear : upgradePrices)[
                      props.subscriptionLevel
                    ];
                  if (!price) {
                    price = props.isYearly
                      ? Number(
                          pricesYear[
                            props.subscriptionLevel as SubscriptionLevel
                          ]!
                        )
                      : Number(
                          monthPricesSub[
                            props.subscriptionLevel as SubscriptionLevel
                          ]!
                        );
                  }
                  return price;
                })(),
              },
            });
            setStage(SubscriptionProviderFlowStage.StartedPayment);
          }}
          bg={HornyButtonBG.Red}
          height={HornyButtonHeight.Shorter}
          className="w-max-content"
        >
          <Icon src={IconSrc.G2A} size={IconSize.Small} />
          {t('Subscribe with G2A')}
        </HornyButton>
        <HornyButton
          onClick={() => {
            setStage(SubscriptionProviderFlowStage.StartedPayment);
          }}
          bg={HornyButtonBG.Red}
          height={HornyButtonHeight.Shorter}
          className="w-max-content"
        >
          <Icon src={IconSrc.G2A} size={IconSize.Small} />
          {t('Already subscribed')}
        </HornyButton>
      </>
    ),

    [SubscriptionProviderFlowStage.StartedPayment]: (
      <div className="d-flex align-items-center gap-2">
        <div className="fw-bold">{t('Enter your code')}: </div>
        <div
          className="ms-2 align-items-center  px-4 text-white rounded-4   horny-bg-dark_opac"
          style={{ minWidth: '200px', height: 24 }}
        >
          <span
            contentEditable={'true'}
            ref={emailRef}
            className="d-flex h-100 w-100 horny-text_tiny lh-1 boosty_input align-items-center  position-relative"
          ></span>
        </div>
        <Icon
          className="cursor-pointer"
          src={IconSrc.Save}
          size={IconSize.Big}
          data-anal="subscribe_process"
          data-anal-state="Code_sent_g2a"
          data-anal-level={props.subscriptionLevel}
          onClick={() => {
            buyG2AProduct();
          }}
        />
        {props.selectedProvider && (
          <HornyButton
            onClick={() => setStage(SubscriptionProviderFlowStage.Init)}
            bg={HornyButtonBG.Red}
            height={HornyButtonHeight.Short}
            className="w-max-content"
          >
            <Icon src={IconSrc.Back} size={IconSize.XSmall} />
            {t('Back')}
          </HornyButton>
        )}
      </div>
    ),
    [SubscriptionProviderFlowStage.StartedVerification]: (
      <HornyButton
        onClick={() => refresh()}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
      >
        <Icon
          src={IconSrc.Reload}
          size={IconSize.Small}
          className={`${refreshing ? 'horny-spin' : ''}`}
        />
        {t('Refresh')}
      </HornyButton>
    ),
    [SubscriptionProviderFlowStage.ReadyToUpdate]: (
      <HornyButton
        data-anal="subscribe_process"
        data-anal-state="Click_upgrade_g2a"
        data-anal-level={props.subscriptionLevel}
        className="w-max-content"
        onClick={() => {
          // window.open(
          //   `https://www.g2a.com/user/Temptationsai/${props.subscriptionId}?`,
          //   '_blank'
          // );
          setStage(SubscriptionProviderFlowStage.Init);
        }}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
      >
        <Icon src={IconSrc.G2A} size={IconSize.Small} />
        {t('Upgrade')}
      </HornyButton>
    ),
  };
  return stageElement[stage];
}
