import { faArrowsRotate, faCamera } from '@fortawesome/free-solid-svg-icons';
import {
  IconDefinition,
  faCircle,
  faPro,
  faSpinnerThird,
  faStars,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faMessage,
  faMessageSlash,
  faThoughtBubble,
  faVideo,
  faVolume,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChatAvailableSetting,
  LLMMemory,
  LLMModelTitle,
} from 'src/@types/common';
import { NewPromptPicker } from 'src/components/CharacterGeneration/ConfigureAppearance/NewPromptPicker';
import {
  ArkSelect,
  ArkSelectItem as SelectItem,
} from 'src/components/Shared/Ark/ArkSelect';
import 'src/components/Shared/tw-base.css';
import { ShowSubscriptionsReason } from 'src/components/Subscription/Subscriptions';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useNutakuContext } from 'src/context/Nutaku.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { ImageGenerationModel } from 'src/services/API/CharacterAPI';
import { ChatAPI } from 'src/services/API/ChatAPI';
import { MediaType } from 'src/services/API/MediaAPI';
import { UserAPI } from 'src/services/API/UserAPI';
import { replaceUserName } from 'src/shared/helpers';
import { cn } from 'src/shared/utils';
import { handleRequestPhotoProps } from '../Chat';
import { ChatNames } from './ChatNames';
import { LeftMessagesCount } from './LeftMessagesCount';
import { RequestInput } from './RequestInput';
import { SlowDown } from './SlowDown';

export enum InteractionType {
  RequestPhoto = 'requestPhoto',
  RequestVideo = 'requestVideo',
  RequestAudio = 'requestAudio',
}

export type InteractionProps = {
  requestTextMessage: (text: string) => void;
  requestMedia: (data: handleRequestPhotoProps) => any;
  Name: string;
  disabledWriting: boolean;
  disableMedia: boolean;
  Author: string;
  IsBotAuthor: boolean;
  BotId: string;
  model: ImageGenerationModel;
  refetchReplySignal: number | undefined;
};

// type LLMModelItem = Omit<SelectItem, 'value'> & {
//   value: LLMModel;
// };

type LLMMemoryItem = Omit<SelectItem, 'value'> & {
  value: LLMMemory;
};

type IconMap = {
  [key: string]: IconDefinition;
};

const iconMap: IconMap = {
  Pro: faPro,
  Starter: faCircle,
  Ultimate: faStars,
};

export function Interaction({
  Author,
  disabledWriting,
  disableMedia,
  Name,
  requestTextMessage,
  requestMedia,
  IsBotAuthor,
  BotId,
  model, // PromptPicker
  refetchReplySignal, // Should refetch Reply?
}: InteractionProps) {
  const { user, setUser } = useUserContext();
  const { isNutaku } = useNutakuContext();
  const { prices } = useResourcesContext();
  const { t } = useTranslation();
  const { capture } = useAnalyticsContext();
  // console.log("MEMORY: ", user!.LLMMemory.toString(), typeof user!.LLMMemory);

  // MEDIA POPOVER
  const [mediaPopupHidden, setMediaPopupHidden] = useState(true);
  const toggleMediaPopup = () => {
    setMediaPopupHidden(!mediaPopupHidden);
  };

  const mediaPopoverRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent): void => {
      if (
        mediaPopoverRef.current &&
        event.target instanceof Node &&
        !mediaPopoverRef.current.contains(event.target)
      ) {
        setMediaPopupHidden(true);
      }
    };

    if (!mediaPopupHidden) {
      document.addEventListener(
        'mousedown',
        handleOutsideClick as EventListener
      );
    }

    return () => {
      document.removeEventListener(
        'mousedown',
        handleOutsideClick as EventListener
      );
    };
  }, [mediaPopupHidden]);

  // /MEDIA POPOVER

  const isPaywall = (mode: ChatAvailableSetting) => {
    return mode.requires_subscription.find(
      (s) => s === user?.MergedSubscription.Level || s === 'Any'
    ) ||
      user?.Upgrades.find((u) => u.Title === mode.requires_upgrade) ||
      mode.trial_days > user?.DaysFromInstall!
      ? undefined
      : ShowSubscriptionsReason.Upgrade;
  };

  const llmModelsNew: SelectItem[] = prices!.ChatAvailableSettings[
    'llm_model'
  ].map((model) => ({
    label: t(model.title as any),
    value: model.title,
    icon: iconMap[model.title],
    paywall: isPaywall(model),
  }));

  const llmMemoryNew: SelectItem[] = prices!.ChatAvailableSettings[
    'llm_memory'
  ].map((memory) => ({
    label: memory.title,
    value: memory.title,
    paywall: isPaywall(memory),
  }));

  const { addModal, removeModal } = useModalContext();

  // Response
  const [selectedUserResponse, setSelectedUserResponse] = useState('');
  const [resetTrigger, setResetTrigger] = useState<number>(0);

  // --- REGENERATE REPLY ---
  const [regenerateReply, setRegenerateReply] = useState(false);

  const [refetch, setRefetch] = useState(false);

  const {
    data: userReply,
    isFetching: isUserReplyLoading,
    refetch: refetchUserReply,
  } = useQuery({
    queryKey: ['userReply', BotId],
    queryFn: () =>
      ChatAPI.userReply({ BotId: BotId, Regenerate: regenerateReply }),
    enabled: user?.IsGenerateChoices || refetch,
  });

  // Send event if there ara tokens
  useEffect(() => {
    if (userReply?.data.Tokens) {
      // capture({
      //   event: 'reply_regenerated',
      //   data: {
      //     is_own_bot: IsBotAuthor,
      //     bot_id: BotId,
      //     tokens_total: userReply.data.Tokens?.total_tokens
      //       ? userReply.data.Tokens.total_tokens
      //       : null,
      //     tokens_completion: userReply.data.Tokens?.completion_tokens
      //       ? userReply.data.Tokens.completion_tokens
      //       : null,
      //     tokens_prompt: userReply.data.Tokens?.prompt_tokens
      //       ? userReply.data.Tokens.prompt_tokens
      //       : null,
      //   },
      // });
    }
  }, [userReply]);

  useEffect(() => {
    if (refetch) {
      refetchUserReply().then(() => {
        setRefetch(false);
        setRegenerateReply(false);
      });
    }
  }, [refetch, refetchUserReply]);

  useEffect(() => {
    if (refetchReplySignal !== undefined) {
      setRegenerateReply(true);
    }
    if (refetchReplySignal !== undefined && user?.IsGenerateChoices) {
      setRefetch(true);
    }
  }, [refetchReplySignal]);
  // --- /REGENERATE REPLY ---

  // --- REPLY CONTEXT ---
  const changeUserIsGenerateChoices = () => {
    setUser({ ...user!, IsGenerateChoices: !user?.IsGenerateChoices });
    UserAPI.changeIsGenerateChoices(!user?.IsGenerateChoices);
  };
  // --- /REPLY CONTEXT ---

  // --- PROMPT PICKER MODAL ---
  const promptPicker = () => {
    const modal = addModal({
      children: (
        <NewPromptPicker
          description={t('Select a propmpt for your media!')}
          charGen={false}
          buttonText={`${t('Request')} ${t(MediaType['Photo'] as any)}`}
          multiplier={1}
          isGenerated={true}
          onPick={(params) => {
            requestMedia(params);
            removeModal(modal);
          }}
          model={model}
        />
      ),
    });
  };
  // --- /PROMPT PICKER MODAL ---

  const variants = {
    open: { height: 'auto', opacity: 1 },
    closed: {
      height: 0,
      opacity: 0,
    },
  };

  const requestText = (text: string) => {
    if (!disabledWriting) {
      return requestTextMessage(text);
    }
  };

  const updateLLMMemoryMutation = useMutation({
    mutationFn: (data: LLMMemory) =>
      ChatAPI.updateLLMMemory({ LLMMemory: data }),
    onMutate: (data) => {
      setUser({
        ...user!,
        LLMMemory: data,
      });
    },
  });

  const updateLLMModelMutation = useMutation({
    mutationFn: (data: LLMModelTitle) =>
      ChatAPI.updateLLMModel({ LLMModel: data }),
    onMutate: (data) => {
      setUser({
        ...user!,
        LLMModel: data,
      });
    },
  });

  return (
    <div className="tw-flex tw-gap-3 tw-flex-col tw-px-5 tw-py-2">
      <ChatNames Author={Author} Name={Name} BotId={BotId} />
      <div className="tw-w-full tw-flex tw-justify-between tw-flex-row">
        <div className="tw-flex tw-relative">
          {!mediaPopupHidden && (
            <div
              className="tw-absolute tw-bg-horny-gray-900 tw-bg-opacity-90 tw-bottom-full tw-mb-2 tw-border tw-border-horny-gray-600 tw-rounded-lg tw-left-0 tw-w-[calc(100%+2rem)] tw-flex tw-flex-col tw-gap-2 tw-p-2 tw-z-50"
              ref={mediaPopoverRef}
            >
              <motion.div
                className={cn(
                  'tw-flex tw-gap-1 tw-flex-row tw-items-center tw-px-4 tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600 tw-text-sm tw-rounded-full tw-shadow-bezel tw-cursor-pointer tw-font-bold tw-py-2 tw-pointer-events-none tw-opacity-50'
                )}
              >
                <FontAwesomeIcon icon={faVolume} />
                <div>{t('Audio')}</div>
              </motion.div>
              <motion.div
                className={cn(
                  'tw-flex tw-gap-1 tw-flex-row tw-items-center tw-px-4 tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600 tw-text-sm tw-rounded-full tw-shadow-bezel tw-cursor-pointer tw-font-bold tw-py-2 tw-pointer-events-none tw-opacity-50'
                )}
              >
                <FontAwesomeIcon icon={faVideo} />
                <div>{t('Video')}</div>
              </motion.div>
              <motion.div
                className={cn(
                  'tw-flex tw-gap-1 tw-flex-row tw-items-center tw-px-4 tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600 tw-text-sm tw-rounded-full tw-shadow-bezel tw-cursor-pointer tw-font-bold tw-py-2',
                  disableMedia && 'tw-opacity-50 tw-pointer-events-none'
                )}
                whileTap={{ scale: 0.85 }}
                onClick={promptPicker}
              >
                <FontAwesomeIcon icon={faCamera} />
                <div>{t('Photo')}</div>
              </motion.div>
            </div>
          )}
          <motion.div
            className={cn(
              'tw-flex tw-gap-2 tw-flex-row tw-items-center tw-px-4 tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600 tw-text-sm tw-rounded-full tw-shadow-bezel tw-cursor-pointer tw-font-bold tw-mr-3',
              disableMedia && 'tw-opacity-50 tw-pointer-events-none'
            )}
            whileTap={{ scale: 0.85 }}
            onClick={toggleMediaPopup}
          >
            <FontAwesomeIcon icon={faCamera} />
            <div className="tw-leading-none">{t('Request media')}</div>
          </motion.div>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-min-h-10">
          {/* {isNutaku && (
            <> */}
          <div className="tw-flex tw-items-center tw-gap-2 tw-relative">
            <div className="tw-absolute tw-text-xs tw-font-bold tw--top-2 tw-w-full tw-text-center tw-text-horny-gray-300 tw-drop-shadow-[0_1px_1px_rgba(0,0,0,0.6)] tw-pointer-events-none tw-leading-none tw-line-clamp-1">
              {t('Memory')}
            </div>
            <ArkSelect
              items={llmMemoryNew}
              defaultValue={user!.LLMMemory.toString()}
              onChange={(e) => {
                updateLLMMemoryMutation.mutate(e as LLMMemory);
              }}
              size="xs"
              placement="top-end"
              icon={faThoughtBubble}
            />
          </div>
          <div className="tw-flex tw-items-center tw-gap-2 tw-relative">
            <div className="tw-absolute tw-text-xs tw-font-bold tw--top-2 tw-w-full tw-text-center tw-text-horny-gray-300 tw-drop-shadow-[0_1px_1px_rgba(0,0,0,0.6)] tw-pointer-events-none tw-leading-none tw-line-clamp-1">
              {t('Chat Quality')}
            </div>
            <ArkSelect
              items={llmModelsNew}
              defaultValue={user?.LLMModel!}
              onChange={(e) => {
                console.log(e);
                updateLLMModelMutation.mutate(e as LLMModelTitle);
              }}
              size="sm"
              placement="top-start"
            />
          </div>
          {/* </>
          )} */}

          <motion.div
            className="tw-flex tw-gap-1 tw-flex-row tw-items-center tw-px-4 tw-h-full tw-bg-gradient-to-b tw-from-horny-orange-400 tw-to-horny-orange-600 tw-text-sm tw-rounded-full tw-shadow-bezel tw-cursor-pointer tw-w-12"
            whileTap={{ scale: 0.85 }}
            onClick={changeUserIsGenerateChoices}
          >
            <FontAwesomeIcon
              icon={user?.IsGenerateChoices ? faMessageSlash : faMessage}
            />
          </motion.div>
        </div>
      </div>
      <motion.div
        className="tw-relative"
        animate={user?.IsGenerateChoices ? 'open' : 'closed'}
        variants={variants}
        initial="open"
      >
        <div
          className={cn(
            'tw-flex tw-flex-row tw-gap-3 tw-items-center tw-w-full tw-my-1 tw-justify-between',
            (disabledWriting || isUserReplyLoading) &&
              'tw-opacity-50 tw-pointer-events-none'
          )}
        >
          <motion.div
            whileTap={{ scale: 0.95 }}
            className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-w-full tw-cursor-pointer"
            onClick={() => {
              setSelectedUserResponse(
                replaceUserName(
                  userReply?.data.Text
                    ? userReply?.data.Text!
                    : 'Nothing here yet...',
                  user!
                )
              );
              setResetTrigger(resetTrigger + 1);
            }}
          >
            <FontAwesomeIcon icon={faMessage} className="tw-flex-shrink-0" />
            {!isUserReplyLoading && (
              <div className="tw-font-bold tw-line-clamp-1">
                {replaceUserName(
                  userReply?.data.Text
                    ? userReply?.data.Text!
                    : 'Nothing here yet...',
                  user!
                )}
              </div>
            )}
            {isUserReplyLoading && (
              <div className="tw-animate-pulse tw-w-full">
                <div className="tw-bg-horny-gray-100 tw-w-full tw-h-6 tw-rounded tw-opacity-60"></div>
              </div>
            )}
          </motion.div>
          <motion.div
            whileTap={{ scale: 0.85 }}
            className="tw-cursor-pointer"
            onClick={() => {
              setRegenerateReply(true);
              setRefetch(true);
            }}
          >
            <FontAwesomeIcon
              icon={isUserReplyLoading ? faSpinnerThird : faArrowsRotate}
              className={cn(isUserReplyLoading && 'tw-animate-spin')}
            />
          </motion.div>
        </div>
      </motion.div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <RequestInput
          BotId={BotId}
          IsBotAuthor={IsBotAuthor}
          defaultValue={selectedUserResponse}
          disabled={disabledWriting}
          onSubmit={async (text: string) => {
            return requestText(text);
          }}
          placeholder={t('Type whatever you want...')}
          dafaultTrigger={resetTrigger}
        />
        <div className="tw-opacity-50 tw-flex tw-items-center tw-justify-center tw-w-full tw-pb-1 tw-gap-3">
          <LeftMessagesCount />
          <SlowDown />
        </div>
      </div>
    </div>
  );
}
