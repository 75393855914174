import { faBadgePercent, faStars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Timer } from 'src/components/Shared/Timer/Timer';
import { useNutakuContext } from 'src/context/Nutaku.Context';
import { cn } from 'src/shared/utils';
import { useModalContext } from '../../../context/Modal.Context';
import { useUserContext } from '../../../context/User.context';
import { Authorization } from '../../Authorization/Authorization';
import { TemptationsButton } from '../../Shared/TemptationsButton';
import { Subscriptions } from '../../Subscription/Subscriptions';

const popupOfferLastShownKey = 'popupOffeLastShown';

export function SubscriptionButton() {
  const { addModal } = useModalContext();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const { isNutaku } = useNutakuContext();

  const offerToShow =
    user?.IsAnonymous || !isNutaku
      ? undefined
      : user?.Offers && user?.Offers.length > 0
        ? user.Offers.sort((a, b) => b.nutaku_price - a.nutaku_price)[0]
        : undefined;

  const popupOfferLastShown: Date | undefined = localStorage.getItem(
    popupOfferLastShownKey
  )
    ? new Date(localStorage.getItem(popupOfferLastShownKey) as string)
    : undefined;

  if (
    !user?.IsAnonymous &&
    offerToShow &&
    (!popupOfferLastShown ||
      popupOfferLastShown.getDate() < new Date().getDate())
  ) {
    addModal({
      showBalance: true,
      children: <Subscriptions defaultTab="offer" />,
      className: 'horny-bg-dark_opac',
      showSubscriptionButton: false,
    });
    localStorage.setItem(popupOfferLastShownKey, new Date().toISOString());
  }

  if (offerToShow)
    return (
      <motion.button
        className="tw-relative tw-cursor-pointer"
        whileTap={{ scale: 0.85 }}
        onClick={() => {
          addModal({
            showBalance: true,
            children: <Subscriptions defaultTab="offer" />,
            className: 'horny-bg-dark_opac',
            showSubscriptionButton: false,
          });
        }}
      >
        <div className="tw-relative tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-rounded-full">
          <div
            className="tw-absolute tw-size-52 tw-shrink-0 tw-animate-spin-slow"
            style={{
              background: `conic-gradient(
              rgba(255, 255, 255, 0) 0%, 
              rgba(255, 255, 255, 0) 60%,
              rgba(255, 255, 255, 1) 95%,
              rgba(255, 255, 255, 0) 100%)`,
            }}
          />
          <div
            className={cn(
              'tw-shadow-bezel tw-font-bold tw-w-fit',
              'tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600',
              'tw-px-4 tw-py-2 tw-text-xs tw-rounded-full',
              'tw-w-fit',
              'tw-relative tw-z-10 tw-m-[1px] tw-overflow-hidden'
            )}
          >
            <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
              <div className="tw-grid tw-place-content-center [&>*]:tw-row-span-full [&>*]:tw-col-span-full">
                <FontAwesomeIcon icon={faBadgePercent} />
                <FontAwesomeIcon
                  icon={faBadgePercent}
                  className="tw-animate-ping tw-opacity-50"
                />
              </div>
              <div>{t(offerToShow.offer)}</div>
            </div>
          </div>
        </div>
        <div className="tw-absolute tw-z-30 tw-text-xxs tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-font-bold [&>*]:tw-bg-horny-gray-600 [&>*]:tw-rounded [&>*]:tw-px-[2px] [&>*]:tw-py-[1px] [&>*]:tw-border-[0.5px] [&>*]:tw-border-horny-gray-300 [&>*]:tw-leading-none tw-flex tw-flex-row tw-items-center [&>*]:tw-shadow-sm tw-w-full tw-justify-center tw-gap-1">
          <div>
            <Timer onTimeReach={() => {}} timerDays={2} />
          </div>
          <div>-{offerToShow.discout}%</div>
          <div>{t('Personal Offer')}</div>
        </div>
      </motion.button>
    );

  if (user?.MergedSubscription.Level === null) {
    return (
      <TemptationsButton
        color="red"
        size="xs"
        onClick={() => {
          addModal({
            showBalance: user?.IsAnonymous ? false : true,

            children: user!.IsAnonymous ? (
              <Authorization />
            ) : (
              <Subscriptions source="user_balance" />
            ),
            showSubscriptionButton: false,
            className: user!.IsAnonymous
              ? 'z-50 horny-bg-gradient_red'
              : 'horny-bg-dark_opac',
          });
        }}
      >
        <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
          <FontAwesomeIcon icon={faStars} />
          <div>{t('Subscribe')}</div>
        </div>
      </TemptationsButton>
    );
  }

  return null;
}
