import { Axios } from 'axios';
import { getAxiosInstance, getAxiosInstanceV2 } from '../axios/axios';
export type APIProps = {
  client: Axios;
};

export type PaginationCommonParams = {
  StartFrom: number;
  PageSize: number;
};

export type PaginationResponseProps = PaginationCommonParams & {
  Total: number;
};

export type PaginationResponse<T extends Record<string, any>> =
  PaginationResponseProps & T;

const client = getAxiosInstance();
const clientV2 = getAxiosInstanceV2();
export class APIBase {
  static client = client;

  static clientV2 = clientV2;
}
