import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppearancePrompts, GenerateBotImagesRequest } from 'src/@types/common';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Price } from 'src/components/Shared/Price/Price';
import {
  ShowSubscriptionsReason,
  Subscriptions,
} from 'src/components/Subscription/Subscriptions';
import { useModalContext } from 'src/context/Modal.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import {
  CharacterAPI,
  CharacterGenerationAppearance,
  CharacterGenerationDraftAPIModel,
  CharacterGenerationStage,
  ImageGenerationModelType,
} from 'src/services/API/CharacterAPI';
import { OnChoiceCallback } from '../CharacterGeneration';
import { IntroBlock } from '../Shared/IntroBlock';
import {
  TypePreview,
  TypePreviewColor,
  TypePreviewSize,
} from '../Shared/TypePreview/TypePreview';
import { checkState } from '../Shared/helpers';
import { NewPromptPicker } from './NewPromptPicker';
import './index.css';

export type ConfigureProps = {
  onChoice: OnChoiceCallback;
  onForward?: (() => any) | null;
  fetched: CharacterGenerationAppearance | null;
  onBack: () => any;
  isGenerated: boolean;
  model: ImageGenerationModelType;
  onFilter?: (key: string, value: string | null) => any;
};

export function ConfigureAppearance({
  onChoice,
  onForward,
  fetched,
  onBack,
  isGenerated,
  model,
  onFilter,
}: ConfigureProps) {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const fetchedRef = useRef<boolean>(false);
  const [appearanceCategories, setAppearanceCategories] =
    useState<AppearancePrompts | null>(null);
  const [appearance, setAppearance] = useState<CharacterGenerationAppearance>(
    {}
  );
  // const [modalId, setModalId] = useState<number | null>(null);
  const { addModal, removeModal } = useModalContext();
  const [isFilled, setIsFilled] = useState(false);
  const { prices } = useResourcesContext();
  const [error, setError] = useState('');

  // const [modal, setModal] = useState<number | null>(null);
  useEffect(() => {
    setIsFilled(
      !!appearanceCategories &&
        Object.keys(appearanceCategories).length ===
          Object.keys(appearance).length
    );
  }, [appearanceCategories, appearance]);

  const [updating, setUpdating] = useState(false);

  const updateDraft = (genParams: GenerateBotImagesRequest) => {
    if (!isFilled) {
      return;
    }
    setUpdating(true);
    const stage = CharacterGenerationStage.Appearance;
    const data: CharacterGenerationDraftAPIModel[typeof stage] = appearance;
    // console.log({ stage, data, genParams });
    onChoice(stage, data, genParams).finally(() => setUpdating(false));
  };

  const setValueFromControl = (category: string, prompt: string) => {
    setAppearance({
      ...appearance,
      [category]: prompt,
    });
    onFilter && onFilter(category, prompt);
  };

  useEffect(() => {
    if (!fetchedRef.current) {
      fetchedRef.current = true;
      CharacterAPI.getAppearanceParams({ Model: model })
        .then(({ data }) => {
          setAppearanceCategories(data.Prompts);
          if (
            fetched &&
            Object.keys(fetched).length === Object.keys(data.Prompts).length
          ) {
            setAppearance(fetched);
          }
          // const appearanceToMerge = appearance;
          // data
          //   .filter(
          //     ({ Control }) => Control === CharacterAppearanceControl.Range
          //   )
          //   .forEach(({ CategoryValue, Prompts }) => {
          //     appearanceToMerge[CategoryValue] =
          //       appearance[CategoryValue] || Prompts[1].PromptValue;
          //   });
          // setAppearance(appearanceToMerge);
        })
        .finally(() => {
          // fetchedRef.current = false;
        });
    }
  });

  const callPicker = () => {
    // const appearanceConfig = Object.fromEntries(Object.entries(appearance).map(([key, val]) => {
    //   const title = appearanceCategories.find(c => c.CategoryValue === key)!.Title;
    //   return ['Please select ' + title, !!val];
    // }))
    const appearanceConfig: Record<string, boolean> = {};
    Object.entries(appearanceCategories!).forEach(([key, val]) => {
      // const title = appearanceCategories.find(c => c.CategoryValue === key)!.Title;
      appearanceConfig[t('Please select') + ' "' + t(key as any) + '"'] =
        !!!appearance[key];
    });
    // const appearanceConfig = Object.fromEntries(appearanceCategories.map((c) => {
    //   // const title = appearanceCategories.find(c => c.CategoryValue === key)!.Title;
    //   return ['Please select ' + c.Title, !!appearance[c.CategoryValue]];
    // }));

    // console.log({ appearanceConfig, appearance });
    if (!checkState(appearanceConfig, (e) => setError(e))) {
      return;
    }
    const modal = addModal({
      children: (
        <>
          {/* <PromptPicker
            description={t('Select a pose for character cover')}
            {...{
              isGenerated,
              multiplier: 1,
              // multiplier: 4,
              // categoryToFilterOut: 'Sex',
              model,
              // showNudity: true,
              onPick: (genParams) => {
                updateDraft(genParams);
                removeModal(modal);
              },
            }}
          /> */}
          <NewPromptPicker
            model={model}
            isGenerated={isGenerated}
            multiplier={1}
            onPick={(genParams) => {
              updateDraft(genParams);
              removeModal(modal);
            }}
            buttonText={t('Generate new images')}
            lockCustromPrompt={true}
          />
        </>
      ),
    });
  };

  if (!appearanceCategories) {
    return null;
  }
  return (
    <div className="w-100 d-flex flex-column gap-2  h-100 text-start position-relative">
      {updating && <FullSizeLoader />}
      <div
        className={`overflow-y-auto horny-disable-scrollbar  ${!onFilter ? ' pt-20  horny-border-bottom pb-3' : 'pt-20 pb-20'}`}
      >
        {onFilter && <div className="pt-10"></div>}
        {onFilter && <div className="pt-3"></div>}
        <div className="d-flex flex-column gap-6">
          {!onFilter && (
            <IntroBlock
              onButtonClick={console.log}
              title={t('Appearance')}
              description={t('Appearance descr')}
            />
          )}

          {Object.entries(appearanceCategories!).map(([key, val]) => {
            return (
              <div className="d-flex flex-column gap-2" key={key}>
                <div className="d-flex justify-content-between">
                  <div className="fs-5 fw-bold">{t(key as any)}</div>
                  {onFilter && (
                    <div
                      className={`horny-text_tiny cursor-pointer ${!appearance[key] ? 'pe-none opacity-50' : ''}`}
                      onClick={() => {
                        onFilter(key, null);
                        setAppearance((prev) => {
                          delete prev[key];
                          return prev;
                        });
                      }}
                    >
                      <Icon src={IconSrc.Clear} size={IconSize.XSmall} />
                      {t('Clear filter')}
                    </div>
                  )}
                </div>
                {/* {Control === CharacterAppearanceControl.Range ? (
                    <Range
                      onChange={(val) =>
                        setValueFromControl(CategoryValue, val)
                      }
                      startPosition={
                        Prompts.findIndex(
                          (p) => appearance[CategoryValue] === p.PromptValue
                        ) || 0
                      }
                      values={Prompts}
                      model={model}
                    />
                  ) : ( */}
                <div className="justify-content-between">
                  {val.map(({ PromptValue, Image }) => (
                    <div
                      className="d-inline-block horny-valign-top me-3 mb-3 justify-content-center"
                      key={PromptValue}
                      //  style={{ width: '82px' }}
                      onClick={() => {
                        setValueFromControl(key, PromptValue);
                        onFilter && onFilter(key, PromptValue);
                      }}
                    >
                      <TypePreview
                        size={TypePreviewSize.Small}
                        src={Image}
                        selected={appearance[key] === PromptValue}
                        color={
                          onFilter
                            ? TypePreviewColor.Grey
                            : TypePreviewColor.Red
                        }
                      />
                      <div
                        className="text-center pt-2 horny-break-words"
                        style={{ width: '84px' }}
                      >
                        {t(PromptValue as any)}
                      </div>
                    </div>
                  ))}
                </div>
                {/* )} */}
              </div>
            );
          })}
        </div>
        {onFilter && <div className="pb-5"></div>}
      </div>
      {!onFilter && (
        <div className=" mx-auto  d-flex gap-4 flex-column align-items-end pb-4">
          {!!error ? (
            <div
              className={`text-center w-100 ${error ? 'scale-up-ver-bottom' : ''}`}
            >
              {error}
            </div>
          ) : (
            <div className="opacity-0">{t('error')}</div>
          )}

          {onForward && !onFilter && (
            <HornyButton
              {...(updating ? { disabled: true } : {})}
              height={HornyButtonHeight.Tall}
              onClick={() => onForward()}
              icon={IconSrc.Next}
              text={t('Back to generated photos')}
              className="mx-auto"
            ></HornyButton>
          )}

          {!onFilter && (
            <HornyButton
              height={HornyButtonHeight.Tall}
              {...(updating ? { disabled: true } : {})}
              onClick={() => {
                if (isGenerated && user?.Energy! < prices!.Media.Photo) {
                  addModal({
                    children: (
                      <Subscriptions
                        source="pose_premium"
                        showReason={ShowSubscriptionsReason.Energy}
                      />
                    ),
                    showSubscriptionButton: false,
                  });
                  return;
                }
                callPicker();
              }}
              icon={!isGenerated ? IconSrc.Next : IconSrc.Reload}
              text={`${updating ? t('Generating...') : !isGenerated ? t('Choose Pose for Cover') : t('Regenerate photos')}`}
              className="mx-auto"
            >
              {isGenerated && (
                <AddBlock className="horny-bg-dark_half">
                  <Price amount={prices!.Media.Photo} />
                </AddBlock>
              )}
            </HornyButton>
          )}

          {!onFilter && (
            <div className="mx-auto cursor-pointer  fw-bold text-decoration-underline">
              <u onClick={onBack}>{t('Back')}</u>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
