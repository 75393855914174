import { useState } from 'react';
import { Image } from 'react-bootstrap';
import { errorImageSrc } from 'src/shared/utils';

export type MediaDisplayPhotoInnerProps = {
  src: string;
};

export default function MediaDisplayPhotoInner({
  src,
}: MediaDisplayPhotoInnerProps) {
  const [isSquare, setIsSquare] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [loading, setLoading] = useState(true);

  return (
    <div className=" position-absolute w-100 h-100 d-flex align-items-center">
      <Image
        className={`${isSquare ? '' : 'h-100'} ${loading && !loadingError ? 'opacity-0' : ''} w-auto mw-100 object-fit-cover  mx-auto`}
        src={(() => {
          console.log({
            loadingError,
            isSquare,
            src,
          });
          if (loadingError) {
            return errorImageSrc;
          }
          if (isSquare) {
            return src + '?width=' + 1024; //Math.min(window.screen.width, 600);
          }
          return src + '?height=' + 1368;
        })()}
        onLoad={(e) => {
          const target = e.target as HTMLImageElement;
          if (target.naturalHeight === target.naturalWidth) {
            setIsSquare(true);
          }
          setLoading(false);
        }}
        onError={() => setLoadingError(true)}
      />
    </div>
  );
}
