import { cn } from 'src/shared/utils';
import { EmojiSrc } from '../Emoji/Emoji';
import { IconSrc, getIconUrl } from './Icon';
import './index.css';

type ImgIconProps = {
  src: IconSrc | EmojiSrc;
  size?: ImgIconSize;
};

type ImgIconSize = 'sm' | 'base';

export function ImgIcon({ src, size }: ImgIconProps) {
  const imgSource = getIconUrl(src as IconSrc);
  return (
    <img src={imgSource} className={cn(
      size === 'sm' && 'tw-w-6'
    )} />

    // <Image
    //   className={`horny-icon d-inline-block ${className} ${sizeClass} ${state ? `horny-icon_${state}` : ''}`}
    //   src={imgSource + '?width=75'}
    //   {...htmlProps}
    // ></Image>
  );
}
