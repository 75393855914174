import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTextArea } from 'src/components/Shared/Input/CustomTextArea';
import 'src/components/Shared/tw-base.css';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { isMobile } from 'src/shared/helpers';
import { cn } from 'src/shared/utils';

const defaultRequestTextContent = '';

export type RequestInputProps = {
  BotId: string;
  disabled: boolean;
  onSubmit: (text: string) => Promise<unknown>;
  placeholder: string;
  IsBotAuthor: boolean;
  defaultValue: string;
  dafaultTrigger: number;
};

export function RequestInput({
  disabled,
  onSubmit,
  placeholder,
  BotId,
  IsBotAuthor,
  defaultValue = '',
  dafaultTrigger,
}: RequestInputProps) {
  const { t } = useTranslation();
  const [requestTextContent, setRequestTextContent] = useState(defaultValue);
  const [shifPressed, setShiftPressed] = useState(false);
  const { capture } = useAnalyticsContext();
  const [isMaxLength, setIsMaxLength] = useState(false);

  useEffect(() => {
    if (requestTextContent !== defaultValue) {
      setRequestTextContent(defaultValue);
    }
  }, [defaultValue, dafaultTrigger]);

  // const analytics = {
  //   [InputPurpose.Place]: {},
  //   [InputPurpose.Text]: {
  //     ['data-anal']: 'message_sent',
  //     ['data-anal-is_suggested']: 'true',
  //     ['data-anal-is_own_bot']: IsBotAuthor,
  //   },
  // };

  const submitText = () => {
    return onSubmit(requestTextContent).then((res) => {
      // capture({
      //   event: 'message_sent',
      //   data: {
      //     request_type: 'generate',
      //     is_own_bot: IsBotAuthor,
      //     is_suggested: false,
      //     bot_id: BotId,
      //   },
      // });
      setRequestTextContent(defaultRequestTextContent);
      setShiftPressed(false);
      // return res;
    });
  };

  const detectShiftPressed = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Shift') {
      setShiftPressed(true);
    }
    return true;
  };

  const detectShiftUp = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Shift') {
      setShiftPressed(false);
    }
    return true;
  };

  const detectEnter = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      if (!isMobile() && shifPressed && !requestTextContent) {
        return false;
      }
      !disabled &&
        !isMobile() &&
        shifPressed &&
        requestTextContent &&
        submitText();
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div
        className={cn(
          'tw-w-full tw-relative',
          disabled && 'tw-pointer-events-none tw-opacity-50'
        )}
      >
        <CustomTextArea
          className="tw-pe-16 tw-align-top"
          color="orange"
          onChange={(value, isMaxLength) => {
            setRequestTextContent(value);
            isMaxLength ? setIsMaxLength(true) : setIsMaxLength(false);
          }}
          value={requestTextContent}
          placeholder={
            isMobile() ? placeholder : t('Press Shift + Enter to send message')
          }
          maxRows={10}
          onKeyDown={(e) => {
            detectShiftPressed(e);
            detectEnter(e);
          }}
          onKeyUp={(e) => detectShiftUp(e)}
          maxLength={1000}
        />
        <div
          className={cn(
            'tw-text-3xl tw-text-horny-orange-700 tw-absolute tw-top-1/2 tw-right-0 tw--translate-y-1/2 tw-mr-5 tw-cursor-pointer',
            (disabled || !requestTextContent || isMaxLength) &&
              'tw-pointer-events-none tw-opacity-50'
          )}
        >
          <motion.div
            whileTap={{ scale: 0.85 }}
            onClick={() => {
              !disabled && submitText();
            }}
          >
            {disabled ? (
              <FontAwesomeIcon
                icon={faSpinnerThird}
                className="tw-animate-spin"
              />
            ) : (
              <FontAwesomeIcon icon={faPaperPlaneTop} />
            )}
          </motion.div>
        </div>
      </div>
    </>
  );
}
