import { Switch } from '@ark-ui/react';
import { useState } from 'react';
import { cn } from 'src/shared/utils';

type ArkSwitchProps = {
  onChange: (e: boolean) => void;
  defaultChecked: boolean;
  label?: string;
  labelPosition?: 'left' | 'right';
  size?: Size;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
};

type Size = 'sm' | 'lg';

const FontSizes: Record<Size, string> = {
  sm: 'tw-text-base',
  lg: 'tw-text-xl',
};

const OuterSizes: Record<Size, string> = {
  sm: 'tw-w-12 tw-h-8',
  lg: 'tw-w-16 tw-h-10',
};

const InnerSizes: Record<Size, string> = {
  sm: 'tw-w-5 tw-h-5',
  lg: 'tw-w-6 tw-h-6',
};

const TrasnaleLeft: Record<Size, string> = {
  sm: 'tw-translate-x-1',
  lg: 'tw-translate-x-2',
};
const TrasnaleRight: Record<Size, string> = {
  sm: 'tw-translate-x-5',
  lg: 'tw-translate-x-8',
};

export const ArkSwitch = ({
  onChange,
  defaultChecked,
  label,
  labelPosition = 'right',
  size = 'lg',
  className,
}: ArkSwitchProps) => {
  const [checked, setChecked] = useState(defaultChecked);
  return (
    <Switch.Root
      className={cn(
        'tw-flex tw-flex-row tw-gap-3 tw-items-center tw-cursor-pointer',
        className
      )}
      onCheckedChange={(e) => {
        setChecked(e.checked);
        onChange(e.checked);
      }}
      defaultChecked={checked}
    >
      {label && labelPosition === 'left' && (
        <Switch.Label className={cn('tw-font-bold', FontSizes[size])}>
          {label}
        </Switch.Label>
      )}
      <Switch.Control
        className={cn(
          'tw-bg-horny-gray-900 tw-border tw-border-horny-gray-600 tw-rounded-full tw-flex tw-items-center tw-h',
          OuterSizes[size]
        )}
      >
        <Switch.Thumb
          className={cn(
            'tw-rounded-full tw-bg-white tw-shadow-bezel tw-block tw-transition-all tw-duration-100',
            InnerSizes[size],
            checked
              ? 'tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600'
              : 'tw-bg-gradient-to-b tw-from-horny-gray-100 tw-to-horny-gray-400',
            checked ? TrasnaleRight[size] : TrasnaleLeft[size]
          )}
        />
      </Switch.Control>
      {label && labelPosition === 'right' && (
        <Switch.Label className={cn('tw-font-bold', FontSizes[size])}>
          {label}
        </Switch.Label>
      )}
      <Switch.HiddenInput />
    </Switch.Root>
  );
};
