import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WavesurferPlayer from '@wavesurfer/react';
import { useEffect, useId, useState } from 'react';

import { useAudioStore } from 'src/components/Audio/AudioStore';
import ArkSlider, {
  ArkSliderValueType,
} from 'src/components/Shared/Ark/ArkSlider';
import WaveSurfer from 'wavesurfer.js';
import { cn } from '../../shared/utils';

const getStoredVolume = (): ArkSliderValueType => {
  const storedVolume = localStorage.getItem('audioPlayerVolume');
  return storedVolume ? (storedVolume as unknown as ArkSliderValueType) : 50;
};

export type AudioPlayerProps = {
  url: string;
  isWaveHidden?: boolean;
};

export type AudioPlayerSceletonProps = {
  isWaveHidden?: boolean;
};

export function AudioPlayerSceleton({
  isWaveHidden = false,
}: AudioPlayerSceletonProps) {
  return (
    <div className="tw-w-full tw-h-16 tw-flex tw-flex-row tw-gap-2">
      <div className="tw-w-16 tw-shrink-0 tw-bg-white tw-rounded-md tw-bg-opacity-25 tw-animate-pulse tw-flex tw-items-center tw-justify-center">
        <FontAwesomeIcon icon={faPlay} className="tw-text-xl tw-opacity-25" />
      </div>
      <div
        className={cn(
          'tw-w-full tw-bg-white tw-rounded-md tw-bg-opacity-25 tw-animate-pulse tw-flex tw-items-center tw-justify-center',
          isWaveHidden && 'tw-hidden'
        )}
      >
        <FontAwesomeIcon
          icon={faSpinnerThird}
          className="tw-text-xl tw-opacity-25 tw-animate-spin"
        />
      </div>
    </div>
  );
}
export default function AudioPlayer({
  url,
  isWaveHidden = false,
}: AudioPlayerProps) {
  const id = useId(); // Уникальный идентификатор для каждого экземпляра AudioPlayer
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { volume, setVolume, currentPlayingId, setCurrentPlayingId } =
    useAudioStore();

  const onReady = (ws: WaveSurfer) => {
    setWavesurfer(ws);
    setIsPlaying(false);
    setIsLoading(false);
    ws.setVolume(volume / 100);
  };

  const onVolumeChange = (value: ArkSliderValueType, ws: WaveSurfer) => {
    const newVolume = value / 100;
    ws.setVolume(newVolume);
    setVolume(value);
  };

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.setVolume(volume / 100);
    }
  }, [volume, wavesurfer]);

  useEffect(() => {
    if (wavesurfer) {
      if (currentPlayingId !== id && isPlaying) {
        wavesurfer.pause();
        setIsPlaying(false);
      }
    }
  }, [currentPlayingId, id, isPlaying, wavesurfer]);

  const onPlayPause = () => {
    if (wavesurfer) {
      if (isPlaying) {
        wavesurfer.pause();
        setCurrentPlayingId(null);
      } else {
        setCurrentPlayingId(id);
        wavesurfer.play();
      }
    }
  };
  return (
    <>
      <div
        className={cn(
          'tw-h-16 tw-cursor-pointer tw-flex tw-flex-row tw-w-full',
          isLoading && 'tw-hidden'
        )}
      >
        <div
          className="tw-text-4xl tw-h-16 tw-w-16 tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-aspect-square"
          onClick={onPlayPause}
        >
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </div>
        <div
          className={cn('tw-w-full', isWaveHidden && 'tw-hidden')}
          // onClick={onPlayPause}
        >
          <WavesurferPlayer
            barWidth={3}
            barGap={2}
            barRadius={5}
            height={48}
            waveColor={'#999'}
            progressColor={'#fff'}
            url={url}
            onReady={onReady}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            interact={true}
          />
        </div>
        <div className="tw-h-full tw-py-1 tw-px-4 tw-shrink-0 tw-flex tw-items-center tw-justify-center">
          <ArkSlider
            value={volume}
            onChange={(value) => {
              wavesurfer && onVolumeChange(value, wavesurfer);
            }}
          />
        </div>
      </div>
      {isLoading && <AudioPlayerSceleton isWaveHidden={isWaveHidden} />}
    </>
  );
}
