import { SetStateAction, useEffect, useState } from 'react';
import { MediaType as MediaTypeType } from 'src/services/API/MediaAPI';
import useKeepScrollPosition from './useKeepScrollPosition';
import useMedia from './useMedia';

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ChatMediaPreview } from 'src/components/Chat/ChatMedia/ChatMediaPreview';
import { CommunityMediaUnavailable } from 'src/components/Media/Shared/CommunityMediaUnavailable';
import { MediaStatusChangeType } from 'src/components/Media/Shared/MediaDisplay/MediaDisplayWrapper';
import { useUserContext } from 'src/context/User.context';
import {
  AnimParamInOutOpacityYNeg100,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { cn } from 'src/shared/utils';
import { MediaBlockSwitcher } from '../Shared/MediaBlockSwitcher/MediaBlockSwitcher';
import { MediaFilter } from '../Shared/MediaFilter/MediaFilter';
import { NoMedia } from '../Shared/NoMedia';
import './../index.css';

export enum MediaBlock {
  Dialogue = 'dialogue',
  Community = 'community',
}
export type MediaProps = {
  selectedMediaBlock: MediaBlock;
  botId: string;
  onSelectMediaBlock: (block: MediaBlock) => any;
};

export enum MediaStatus {
  Init = 'init',
  Idle = 'idle',
  Loading = 'loading',
}
export function DialogueMedia({
  botId,
  selectedMediaBlock,
  onSelectMediaBlock,
}: MediaProps) {
  console.log(selectedMediaBlock);
  const { media, setLastMediumRef, total, status, resetMedia } = useMedia({
    botId,
    selectedMediaBlock,
  });

  const { containerRef } = useKeepScrollPosition([media]);
  // const { addModal } = useModalContext();
  const [selectedMediaType, setSelectedMediaType] =
    useState<MediaTypeType | null>(MediaTypeType.Photo);

  const [init, setInit] = useState(false);
  // const [refresh, setRefresh] = useState(true);

  // const deleteMediaListenerRef = useRef(false);
  const [filteredOutMedia, setFilteredOutMedia] = useState<string[]>([]);

  const handleMediaStatusChange = (status: MediaStatusChangeType) => {
    console.log(status);
    if (selectedMediaBlock === MediaBlock.Dialogue) {
      if (status.status === 'remove') {
        setFilteredOutMedia([...filteredOutMedia, status.MediaId]);
      }
      if (status.status === 'add') {
        setFilteredOutMedia([
          ...filteredOutMedia.filter((id) => id !== status.MediaId),
        ]);
      }
    }
    if (selectedMediaBlock === MediaBlock.Community) {
      if (status.status === 'unpublish') {
        setFilteredOutMedia([...filteredOutMedia, status.MediaId]);
      }
      if (status.status === 'publish') {
        setFilteredOutMedia([
          ...filteredOutMedia.filter((id) => id !== status.MediaId),
        ]);
      }
    }
  };

  useEffect(() => {
    if (!init && containerRef.current && media) {
      //  console.log(containerRef.current);
      setTimeout(() => {
        (containerRef.current as unknown as HTMLElement).scrollTo(0, 0);
      }, 20);
      setInit(true);
    }
  }, [init, media]);

  const { user } = useUserContext();
  const { t } = useTranslation();

  const filteredMedia =
    media
      ?.filter(({ MediaType }) => {
        if (selectedMediaType === null) {
          return MediaType === MediaTypeType.Photo;
        }
        return selectedMediaType === MediaType;
      })
      .filter((m) => !filteredOutMedia.includes(m.MediaId)) || null;

  if (
    !user!.MergedSubscription.Level &&
    selectedMediaBlock === MediaBlock.Community
  ) {
    return (
      <div className="media_container px-2">
        <div className="d-flex gap-2 mb-2 justify-content-between media_controls">
          <MediaFilter
            selectedType={selectedMediaType}
            onSelect={(mediaType) => setSelectedMediaType(mediaType)}
          />
          <MediaBlockSwitcher
            selectedBlock={selectedMediaBlock}
            onSelect={(block) => {
              resetMedia();
              onSelectMediaBlock(block);
            }}
          />
        </div>
        <CommunityMediaUnavailable />
      </div>
    );
  }

  if (!media) {
    return (
      <div className="media_container px-2">
        <div className="d-flex gap-2 mb-2 justify-content-between media_controls">
          <MediaFilter
            selectedType={selectedMediaType}
            onSelect={(mediaType) => setSelectedMediaType(mediaType)}
          />
          <MediaBlockSwitcher
            selectedBlock={selectedMediaBlock}
            onSelect={(block) => {
              resetMedia();
              onSelectMediaBlock(block);
            }}
          />
        </div>
        <div
          ref={(ref) => setLastMediumRef(ref as SetStateAction<any>)}
          className="m-auto text-center h-100 d-flex justify-content-center align-items-center"
        >
          <FontAwesomeIcon icon={faSpinnerThird} size="xl" spin />
        </div>
      </div>
    );
  }
  // if (media.length === 0){
  //   return <NoMedia/>
  // }
  return (
    <div className="media_container px-2">
      <div className="d-flex gap-2 mb-2 justify-content-between media_controls">
        {/* Это переключает типы / не мое */}
        <MediaFilter
          selectedType={selectedMediaType}
          onSelect={(mediaType) => setSelectedMediaType(mediaType)}
        />
        {/* Это переключает мое / не мое */}
        <MediaBlockSwitcher
          selectedBlock={selectedMediaBlock}
          onSelect={(block) => {
            resetMedia();
            onSelectMediaBlock(block);
          }}
        />
      </div>
      {!filteredMedia || filteredMedia.length === 0 ? (
        <NoMedia />
      ) : (
        <motion.div
          className={cn(
            selectedMediaType === MediaTypeType.Photo && 'w-100 m-auto media',
            selectedMediaType === MediaTypeType.Audio &&
              'tw-grid tw-grid-cols-2 tw-gap-2'
          )}
          ref={containerRef}
          animate="in"
          initial="initial"
          // exit="out"
          variants={AnimParamInOutOpacityYNeg100}
          transition={TransTweenAnticipate03}
        >
          {filteredMedia.map((m, i) => {
            if (m.MediaType === MediaTypeType.Photo)
              return (
                <div
                  key={m.MediaId}
                  style={{ height: 0, paddingBottom: '100%' }}
                  className="position-relative overflow-hidden horny-border-gray rounded-2 border"
                  ref={(ref) =>
                    status !== MediaStatus.Loading &&
                    i > media.length - 4 &&
                    total > media.length
                      ? setLastMediumRef(ref as SetStateAction<any>)
                      : null
                  }
                >
                  <ChatMediaPreview
                    {...m}
                    onChange={(status) => {
                      handleMediaStatusChange(status);
                    }}
                  />
                </div>
              );
            if (m.MediaType === MediaTypeType.Audio)
              return (
                <div className="tw-bg-gradient-to-b tw-from-horny-gray-700 tw-to-horny-gray-900 tw-px-2 tw-py-4 tw-border tw-border-horny-gray-600 tw-rounded-md tw-shadow">
                  <ChatMediaPreview
                    {...m}
                    onChange={(status) => {
                      handleMediaStatusChange(status);
                    }}
                    description={m.Description}
                  />
                </div>
              );
          })}
        </motion.div>
      )}
      {status === MediaStatus.Loading && (
        <div className="m-auto text-center  d-flex justify-content-center">
          <FontAwesomeIcon icon={faSpinnerThird} size="xl" spin />
        </div>
      )}
    </div>
  );
}
