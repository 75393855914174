import { Toast, Toaster, createToaster } from '@ark-ui/react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import 'src/components/Shared/tw-base.css';
import './ArkToast.css';

export enum ToastImages {
  did_you_do_it = 'did_you_do_it', // thanos 'everything' meme
}

export const toaster = createToaster({
  placement: 'bottom',
  overlap: true,
  gap: 16,
  removeDelay: 150,
  max: 5,
  duration: 10000,
});

type ShowToastProps = {
  title: string;
  description: string;
  support?: boolean;
  image?: ToastImages;
};

type ToastMeta = {
  support?: boolean;
  image?: ToastImages;
};

export const showToast = ({
  title,
  description,
  support = false,
  image,
}: ShowToastProps) => {
  toaster.create({
    title,
    description,
    meta: {
      support: support,
      image: image,
    } as ToastMeta,
  });
};

export const ArkToast = () => {
  const total = toaster.getCount();
  const { t } = useTranslation();
  return (
    <Toaster toaster={toaster}>
      {(toast) => {
        const meta = toast.meta as ToastMeta;
        return (
          <Toast.Root
            key={toast.id}
            className="toast-root tw-min-w-60 tw-bg-horny-gray-900 tw-border tw-border-horny-gray-600 tw-rounded-md tw-shadow tw-p-6 tw-flex tw-flex-row tw-gap-4 tw-justify-between tw-items-start tw-text-base"
            style={
              {
                '--total': total,
              } as React.CSSProperties
            }
          >
            <div className="tw-flex tw-flex-col tw-gap-2">
              <Toast.Title className="tw-font-bold">{toast.title}</Toast.Title>
              <Toast.Description>{toast.description}</Toast.Description>
              {meta.image && (
                <img
                  src={`${process.env.REACT_APP_CDN_HOST}/img/toast/${meta.image}.png`}
                  className="tw-max-w-96 tw-rounded-md tw-border tw-border-horny-gray-900"
                />
              )}
              {meta.support && (
                <Toast.ActionTrigger
                  className="tw-underline tw-underline-offset-2"
                  onClick={() =>
                    window.open(
                      'https://temptationgames.com/#block-ad19c8cf0b23468ab41bb66f7d6af393',
                      '_blank'
                    )
                  }
                >
                  {t('Contact support')}
                </Toast.ActionTrigger>
              )}
            </div>
            <Toast.CloseTrigger>
              <FontAwesomeIcon icon={faXmark} size="xl" />
            </Toast.CloseTrigger>
          </Toast.Root>
        );
      }}
    </Toaster>
  );
};
