import { useTranslation } from 'react-i18next';
import { useModalContext } from '../../../context/Modal.Context';
import { useUserContext } from '../../../context/User.context';
import { ArkSwitch } from '../../Shared/Ark/ArkSwitch';
import { ShowSubscriptionsReason, Subscriptions } from '../../Subscription/Subscriptions';
import { MouseEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';

export type PrivateCharacterSwitcherProps = {
    IsPrivate: boolean;
    onChange: (value: boolean) => any;
}
export function PrivateCharacterSwitcher({IsPrivate, onChange}: PrivateCharacterSwitcherProps){
    const {user} = useUserContext();
    const {addModal} = useModalContext();
    const [t] = useTranslation();
    const [isPrivate, setIsPrivate] = useState(IsPrivate);
    useEffect(() => {
        setIsPrivate(IsPrivate)
    }, [IsPrivate]);

    const checkAvailability = (e?: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        if (isPrivate === true){
            return true;
        }
      
        if ( (!user?.Subscription.Level && !user?.InviteSubscription.Level) || (user.Subscription.Level === 'Friend')){
            if (!e){
              return false;
            }
             addModal({
                children: <Subscriptions
                showReason={ShowSubscriptionsReason.PrivateBot}/>,
                showSubscriptionButton: false,
            });
            e && e.preventDefault();
        }
        return true;
    }

    // const onPrivateSwitch = (value: boolean) => {
    //     if (value === false){
    //         return onChange(value);
    //     }
    //     return onChange(value);
    // }
    return   <div className="tw-flex tw-flex-row tw-gap-2 align-items-center">
    <div onClick={(e) => checkAvailability(e)}>
        <ArkSwitch
      defaultChecked={isPrivate}
      onChange={(value) => onChange(value)}
      size={'sm'}
      className="tw-float-left tw-mr-2"
    />
    </div>
    <div className='d-flex gap-1 align-items-center'>
      {t(
        'Make your character private'
      )}
      {!checkAvailability() && <FontAwesomeIcon icon={faLock}/>}
    </div>
  </div>
}