import { faBolt, faCoinVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../../../context/Modal.Context';
import { useUserContext } from '../../../context/User.context';
import { SubscriptionLevel } from '../../../services/API/ResourcesAPI';
import { Authorization } from '../../Authorization/Authorization';
import { Timer } from '../../Shared/Timer/Timer';
import { Subscriptions } from '../../Subscription/Subscriptions';

export function HeaderCurrencies() {
  const { t } = useTranslation();
  const { addModal } = useModalContext();
  const { user } = useUserContext();
  const energyToShow =
    user?.MergedSubscription.Level === SubscriptionLevel.Boss ||
    user?.MergedSubscription.Level === SubscriptionLevel.Master ? (
      <span>&infin;</span>
    ) : user?.Energy! <= 0 ? (
      <span>
        <Timer onTimeReach={console.log} />
      </span>
    ) : (
      <span>{user?.Energy}</span>
    );
  const voiceCreditsToShow = <span>{user?.Credits}</span>;
  return (
    <motion.div
      className="tw-px-4 tw-py-2 tw-text-xs tw-rounded-full tw-flex tw-items-center tw-flex-row tw-bg-black tw-bg-opacity-50 tw-font-bold tw-cursor-pointer"
      whileTap={{ scale: 0.8 }}
      onClick={() => {
        addModal({
          children: user?.IsAnonymous ? (
            <Authorization />
          ) : (
            <Subscriptions source="user_balance" />
          ),
          className: user?.IsAnonymous
            ? 'z-50 horny-bg-gradient_red'
            : 'horny-bg-dark_opac',
          showBalance: user?.IsAnonymous ? false : true,
          showSubscriptionButton: false,
        });
      }}
    >
      <div className="tw-flex tw-items-center tw-gap-1 tw-flex-row tw-border-r tw-border-r-white tw-border-opacity-25 tw-pe-3">
        <FontAwesomeIcon icon={faBolt} />
        <div>{energyToShow}</div>
      </div>
      <div className="tw-flex tw-items-center tw-gap-1 tw-flex-row tw-ps-3">
        <FontAwesomeIcon icon={faCoinVertical} />
        <div>{voiceCreditsToShow}</div>
      </div>
    </motion.div>
  );
}
