export const TransTweenAnticipate03 = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.3,
  staggerChildren: 0.5,
};

export const TransTweenEaseInOut03 = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.15,
  staggerChildren: 0.5,
};

export const TransTweenAnticipate05 = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5,
};

export const TransSpringPopup = {
  type: 'spring',
  duration: 0.35,
};

export const AnimParamInOutOpacityY100 = {
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: -100,
  },
  initial: {
    opacity: 0,
    y: 100,
  },
};

export const AnimParamInOutOpacityYNeg100 = {
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: 100,
  },
  initial: {
    opacity: 0,
    y: -100,
  },
};

export const AnimParamSmallScaleOpacity = {
  in: {
    scale: 1,
  },
  out: {
    scale: 0,
  },
};

export const AnimParamInOutOpacityXNeg100 = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: 100,
  },
  initial: {
    opacity: 0,
    x: -100,
  },
};

export const AnimParamNutakuCopy = {
  in: {
    opacity: 1,
    y: -15,
  },
  initial: {
    opacity: 0,
    y: 0,
  },
};

export const AnimParamConnection = {
  in: {
    scaleY: 1,
    opacity: 1,
  },
  out: {
    scaleY: 0,
    opacity: 0,
  },
  initial: {
    x: -10,
    opacity: 1,
  },
};

export const AnimButtonError = {
  initial: {
    y: 0,
    opacity: 0,
  },
  in: {
    y: -25,
    opacity: 1,
  },
  out: {
    opacity: 0,
  }
};
