import {
  faList,
  faScrewdriverWrench,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faBolt,
  faLockKeyhole,
  faRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ImageGenerationModelType,
  maxCustomPrompts,
  MediaPrompt,
} from 'src/@types/common';
import { handleRequestPhotoProps } from 'src/components/Chat/Chat';
import {
  ArkRadioGroup,
  RadioItem,
} from 'src/components/Shared/Ark/ArkRadioGroup';
import { TemptationsButton } from 'src/components/Shared/TemptationsButton';
import {
  ShowSubscriptionsReason,
  Subscriptions,
} from 'src/components/Subscription/Subscriptions';
import { useModalContext } from 'src/context/Modal.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { NudityLevel } from 'src/services/API/ResourcesAPI';
import {
  AnimButtonError,
  AnimParamInOutOpacityY100,
  TransSpringPopup,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { cn } from 'src/shared/utils';
import CustomPromptTab from './PromptPickerComponents/CustomPromptTab';
import PredefinedPromptTab from './PromptPickerComponents/PredefinedPromptTab';
import { usePromptPickerStore } from './PromptPickerStore';

export type PromptPickerProps = {
  onPick?: (data: handleRequestPhotoProps) => any;
  model: ImageGenerationModelType;
  isGenerated: boolean;
  multiplier: number;
  buttonText: string;
  charGen?: boolean;
  description?: string;
  lockCustromPrompt?: boolean;
};

export function NewPromptPicker({
  charGen = true,
  lockCustromPrompt = false,
  description = 'Select the pose for the photo',
  ...props
}: PromptPickerProps) {
  const promptPickerStore = usePromptPickerStore();
  const { t } = useTranslation();
  const { prices } = useResourcesContext();
  const { user } = useUserContext();
  const { addModal } = useModalContext();
  const { NudityLevels } = useResourcesContext().mediaPrompts!;

  // api params
  const [selectedPrompt, setSelectedPropmpt] = useState<MediaPrompt | null>(
    null
  );

  // errors
  const [showButtonError, setShowButtonError] = useState(false);
  const [buttonError, setButtonError] = useState('');
  const handleError = (error: string) => {
    setButtonError(error);
    setShowButtonError(true);
    setTimeout(() => {
      setShowButtonError(false);
    }, 1000);
  };

  // tabs
  const [currentTab, setCurrentTab] = useState<'predefined' | 'custom'>(
    'predefined'
  );

  // nudity levels
  const allNudityLevelsRadioItems: RadioItem[] = NudityLevels.map((item) => {
    return {
      value: item.Title,
      label: t(item.Title),
      disabled: false,
    };
  });

  const radioNudityLevels = useMemo(() => {
    if (currentTab === 'predefined' && selectedPrompt) {
      return allNudityLevelsRadioItems.map((nudityLevel) => {
        const isAllowed = selectedPrompt.AllowedNudityLevels.includes(
          nudityLevel.value as NudityLevel
        );
        return isAllowed ? nudityLevel : { ...nudityLevel, disabled: true };
      });
    }
    return allNudityLevelsRadioItems;
  }, [currentTab, selectedPrompt]);

  const [nudity, setNudity] = useState<NudityLevel>(
    allNudityLevelsRadioItems[0].value as NudityLevel
  );

  const { mediaPrompts } = useResourcesContext();
  const isCustomPrompts = mediaPrompts?.CustomPrompts[props.model]
    ? true
    : false;

  return (
    <motion.div
      className="tw-pb-6 tw-w-full tw-h-full tw-flex tw-flex-col tw-bg-gradient-to-b tw-from-horny-gray-600 tw-to-horny-gray-900 tw-px-6 tw-pt-20 tw-gap-4"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="tw-text-3xl tw-font-bold">
        {t('What do you want to see?')}
      </div>
      <div className="tw-text-lg">{t('Select a propmpt for your media!')}</div>
      <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-shadow tw-rounded-md  tw-scrollbar-hide">
        {/* Tabs */}
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div
            className={cn(
              'tw-w-full tw-py-4 tw-px-6 tw-font-bold tw-text-base tw-rounded-t-md tw-flex tw-flex-row tw-items-center tw-gap-2 tw-justify-center',
              currentTab === 'predefined'
                ? 'tw-bg-horny-gray-900 tw-cursor-default'
                : 'tw-bg-horny-gray-800 tw-border-b tw-border-r tw-border-horny-gray-600 tw-cursor-pointer'
            )}
            onClick={() => {
              setCurrentTab('predefined');
            }}
          >
            <span>
              <FontAwesomeIcon
                icon={faList}
                className="tw-inline-block tw-mr-2"
              />
              {t('Predefined List')}
            </span>
          </div>
          <div
            className={cn(
              'tw-w-full tw-py-4 tw-px-6 tw-font-bold tw-text-base tw-rounded-t-md tw-flex tw-flex-row tw-items-center tw-gap-2 tw-justify-center tw-relative ',
              currentTab === 'custom'
                ? 'tw-bg-horny-gray-900 tw-cursor-default'
                : 'tw-bg-horny-gray-800 tw-border-b tw-border-l tw-border-horny-gray-600 tw-cursor-pointer',
              !isCustomPrompts && 'tw-pointer-events-none'
            )}
            onClick={() => {
              setCurrentTab('custom');
            }}
          >
            <span>
              <FontAwesomeIcon
                icon={faScrewdriverWrench}
                className="tw-inline-block tw-mr-2"
              />
              {t('Custom Prompt')}
            </span>
            {(!user?.Upgrades ||
              !user.Upgrades.some((u) => u.Title === 'ControlUp')) && (
              <div className="tw-absolute tw-top-0 tw-right-0 tw-flex tw-flex-row tw-items-center tw-gap-1 tw-bg-black tw-bg-opacity-15 tw-text-xxs tw-rounded-bl-md tw-px-2 tw-py-1">
                <div>{t('Upgrade')}</div>
                <FontAwesomeIcon icon={faLockKeyhole} />
              </div>
            )}
            {!isCustomPrompts && (
              <div className="tw-absolute tw-inset-0 tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-1 tw-bg-black tw-bg-opacity-75 tw-text-xxs">
                <div>{t('Unavailable')}</div>
                <FontAwesomeIcon icon={faLockKeyhole} />
              </div>
            )}
          </div>
        </div>
        {currentTab === 'predefined' && (
          <PredefinedPromptTab
            model={props.model}
            charGen={charGen}
            onSelect={(prompt) => {
              setSelectedPropmpt(prompt);
              setNudity(prompt?.AllowedNudityLevels[0]!);
            }}
          />
        )}
        {currentTab === 'custom' && (
          <CustomPromptTab model={props.model} onChange={() => {}} />
        )}
        <div
          className={cn(
            'tw-px-4 tw-py-4 tw-bg-horny-gray-800 tw-rounded-b-md tw-border-t tw-border-t-horny-gray-600 tw-flex tw-justify-center'
          )}
        >
          <div
            className={cn(
              currentTab === 'predefined' &&
                selectedPrompt === null &&
                'tw-opacity-50 tw-pointer-events-none tw-cursor-default'
            )}
          >
            <ArkRadioGroup
              items={radioNudityLevels}
              onChange={(value) => {
                setNudity(value as NudityLevel);
              }}
            />
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-flex tw-justify-center tw-items-center tw-relative">
        <AnimatePresence mode="wait">
          {showButtonError && (
            <motion.div
              className="tw-absolute tw-w-full tw-text-center tw-font-bold tw-top-0 tw-pb-1"
              initial="initial"
              animate="in"
              exit="out"
              variants={AnimButtonError}
              transition={TransTweenAnticipate03}
            >
              {buttonError}
            </motion.div>
          )}
        </AnimatePresence>
        <TemptationsButton
          color="red"
          size="lg"
          onClick={() => {
            if (currentTab === 'predefined' && selectedPrompt === null) {
              handleError(t('Select an item to continue'));
              return;
            }
            if (
              currentTab === 'custom' &&
              promptPickerStore.selectedCutromPrompts.length < 1
            ) {
              handleError(t('Add custom prompts to continue'));
              return;
            }
            if (
              currentTab === 'custom' &&
              promptPickerStore.selectedCutromPrompts.length > maxCustomPrompts
            ) {
              handleError(t('Too many custom prompts'));
              return;
            }
            if (
              currentTab === 'custom' &&
              (!user?.Upgrades ||
                !user.Upgrades.some((u) => u.Title === 'ControlUp'))
            ) {
              return addModal({
                children: (
                  <Subscriptions
                    source="pose_custom"
                    showReason={ShowSubscriptionsReason.Premium}
                    defaultTab="upgrade"
                  />
                ),
                showSubscriptionButton: false,
              });
            }
            props.onPick!(
              currentTab === 'predefined'
                ? {
                    PromptId: selectedPrompt?.Id,
                    NudityLevel: nudity,
                    Type: currentTab,
                  }
                : {
                    CustomPrompt: promptPickerStore.selectedCutromPrompts,
                    NudityLevel: nudity,
                    Type: currentTab,
                  }
            );
          }}
        >
          <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
            <FontAwesomeIcon icon={faRight} />
            <div>{t(props.buttonText)}</div>
            {props.isGenerated && (
              <div className="tw-text-xs tw-bg-black tw-bg-opacity-50 tw-rounded-full tw-px-3 tw-py-1 tw-flex tw-items-center tw-gap-1 tw-ml-1">
                <FontAwesomeIcon icon={faBolt} />
                <div>{prices!.Media.Photo * props.multiplier}</div>
              </div>
            )}
          </div>
        </TemptationsButton>
      </div>
    </motion.div>
  );
}
