import { motion } from 'framer-motion';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialogue } from 'src/components/Dialogue/Dialogue';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { TopBot } from 'src/services/API/BotAPI';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
export type TopBotCardProps = {
  highestOwnBotRank: number;
};
const getMedalBlock = (rank: number) => {
  const topPlacesMedalIcons: Record<string, IconSrc> = {
    1: IconSrc.MedalGold,
    2: IconSrc.MedalSilver,
    3: IconSrc.MedalBronze,
  };

  if (rank < 4) {
    return (
      <Icon src={topPlacesMedalIcons[rank.toString()]} size={IconSize.Big} />
    );
  } else {
    return (
      <div style={{ width: '24px', height: '24px' }}></div>
      // <Icon src={IconSrc.MedalBronze} size={IconSize.Big} />
    );
    // return <div style={{ width: 24 }}></div>;
  }
};

export function TopBotCard(bot: TopBot & TopBotCardProps) {
  const { t } = useTranslation();
  const { addModal } = useModalContext();
  const { capture } = useAnalyticsContext();
  const [isFetching, setIsFetching] = useState(false);
  const [width, height] = useWindowSize();

  const createChat = async () => {
    if (isFetching) {
      return true;
    }
    setIsFetching(true);

    return await ConnectionsAPI.getChat({
      BotId: bot.BotId,
      StartFrom: 0,
      PageSize: 20,
    }).then(({ data }) => {
      capture({
        event: 'page_open',
        data: {
          page_name: 'Dialogue',
        },
      });
      addModal({
        children: <Dialogue {...data}></Dialogue>,
        // bg: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${data.Chat.CoverUrl})  center no-repeat`,
      });
      setIsFetching(false);
      return true;
    });

    // return ConnectionsAPI.createChat({ BotId: bot.BotId })
    // .then(async () => {
    //   return await ConnectionsAPI.getChat({
    //     BotId: bot.BotId,
    //     StartFrom: 0,
    //     PageSize: 20,
    //   }).then(({ data }) => {
    //     capture({
    //       event: 'page_open',
    //       data: {
    //         page_name: 'Dialogue',
    //       },
    //     });
    //     addModal({
    //       children: <Dialogue {...data}></Dialogue>,
    //       // bg: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${data.Chat.CoverUrl})  center no-repeat`,
    //     });
    //   });
    // })
    // .finally(() => {
    //   setIsFetching(false);
    //   return true;
    // });
  };
  const lowTierScreen = width < 440;

  if (lowTierScreen) {
    return (
      <div
        style={{
          ...(bot.IsOwnBot
            ? {
                marginLeft: -15,
                marginRight: -15,
                paddingLeft: 15,
                paddingRight: 15,
              }
            : {}),
        }}
        className={`  py-5 position-relative d-flex  justify-content-between align-items-center gap-2 ${bot.IsOwnBot ? 'border border-2 border-dashed border-white rounded-4 border-opacity-50 ' : ''}`}
      >
        {bot.IsOwnBot && bot.Rank === bot.highestOwnBotRank && getUserTobBot()}
        <div className="d-flex gap-2">
          <div className="d-flex align-items-center">
            <div>{getMedalBlock(bot.Rank)}</div>
            <div className="fs-3 fw-bold me-3 ps-">#{bot.Rank}</div>
          </div>
          <div>
            <div className="d-flex gap-2 align-items-center justify-content-start w-100 mb-2">
              <div className="d-flex gap-2 align-items-center justify-content-start ">
                {/* <div>{getMedalBlock(bot.Rank)}</div>
            <div className="fs-4 fw-bold">#{bot.Rank}</div> */}
                <div>
                  <img
                    style={{ width: 32, height: 32 }}
                    src={bot.Avatar + '?height=64'}
                    className="rounded-circle border border-solid border-1  border-opacity-50 border-white"
                  />
                </div>
                <div className="">
                  <div className="fw-bold  ">{bot.Name}</div>
                  <div className="horny-text_very_tiny opacity-75">
                    @{bot.Author}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between w-100 align-items-end ps-1">
              {getTopBotStats(bot, 24)}
            </div>
          </div>
        </div>
        <div
          className=" cursor-pointer d-flex align-items-center gap-2  horny-text_tiny"
          onClick={createChat}
        >
          <Icon
            size={IconSize.XSmall}
            src={isFetching ? IconSrc.Loader : IconSrc.Chat}
            className={isFetching ? 'horny-spin' : ''}
          />
          <u className="fw-bold">{t('Chat')}</u>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        ...(bot.IsOwnBot
          ? {
              marginLeft: -15,
              marginRight: -15,
              paddingLeft: 15,
              paddingRight: 15,
            }
          : {}),
      }}
      className={`  py-5 position-relative d-flex  justify-content-between align-items-center gap-4 ${bot.IsOwnBot ? 'border border-2 border-dashed border-white rounded-4 border-opacity-50 ' : ''}`}
    >
      {bot.IsOwnBot && bot.Rank === bot.highestOwnBotRank && getUserTobBot()}

      <div className="d-flex gap-2 align-items-center">
        <div className="d-flex gap-2 align-items-center">
          <div className="d-flex">
            <div>{getMedalBlock(bot.Rank)}</div>
            <div className="fs-3 fw-bold">#{bot.Rank}</div>
          </div>
          <div className="ps-2">
            <img
              style={{ width: 32, height: 32 }}
              src={bot.Avatar + '?height=64'}
              className="rounded-circle border border-solid border-1  border-opacity-50 border-white"
            />
          </div>
          <div style={{ width: 150, maxWidth: 150 }}>
            <div className="fw-bold   ">{bot.Name}</div>
            <div className="horny-text_very_tiny  fw-bold">@{bot.Author}</div>
          </div>
        </div>
        <div className="opacity-75 d-flex align-items-center gap-1 me-1 horny-text_very_tiny">
          <Icon src={IconSrc.Add1} size={IconSize.XSmall} />
          {bot.Likes}
        </div>
        <div className="opacity-75 d-flex align-items-center gap-1 me-1 horny-text_very_tiny">
          <Icon src={IconSrc.Photo} size={IconSize.XSmall} /> {bot.Media}
        </div>
        <div className="opacity-75 d-flex align-items-center gap-1 me-1 horny-text_very_tiny">
          <Icon src={IconSrc.Chat} size={IconSize.XSmall} /> {bot.Messages}
        </div>
      </div>
      <motion.div
        whileTap={{ scale: 0.8 }}
        className=" cursor-pointer d-flex align-items-center gap-2 horny-text_tiny "
        onClick={createChat}
      >
        <Icon
          size={IconSize.XSmall}
          src={isFetching ? IconSrc.Loader : IconSrc.Chat}
          className={isFetching ? 'horny-spin' : ''}
        />
        <u className="fw-bold">{t('Chat')}</u>
      </motion.div>
    </div>
  );
}

export const getUserTobBot = () => (
  <div
    className="position-absolute w-100 text-center bg-grey d-flex justify-content-center "
    style={{ left: 0, top: -13 }}
  >
    <div className="text-center py-2 px-3 horny-bg_grey rounded-3 horny-text_very_tiny">
      Your top character in this category
    </div>
  </div>
);

export const getTopBotStats = (bot: TopBot, paddingLeft: number = 0) => (
  <div className={`d-flex gap-2`}>
    {' '}
    {/* ${paddingLeft ? 'ms-2' : '' }`} style={{paddingLeft}} > */}
    {/* <div className="fs-4 fw-bold">#{bot.Rank}</div> */}
    {/* <div>{getMedalBlock(bot.Rank)}</div>
    <div className="fs-4 fw-bold me-3 ps-1 ">#{bot.Rank}</div> */}
    <div className="opacity-75 d-flex align-items-center gap-1 me-1 horny-text_very_tiny ">
      {/*  */}

      <Icon src={IconSrc.Add1} size={IconSize.XSmall} />
      {bot.Likes}
    </div>
    <div className="opacity-75 d-flex align-items-center gap-1 me-1 horny-text_very_tiny ">
      <Icon src={IconSrc.Photo} size={IconSize.XSmall} /> {bot.Media}
    </div>
    <div className="opacity-75 d-flex align-items-center gap-1 me-1 horny-text_very_tiny ">
      <Icon src={IconSrc.Chat} size={IconSize.XSmall} /> {bot.Messages}
    </div>
  </div>
);

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
