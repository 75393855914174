import CreditsShopElement from 'src/components/Subscription/CreditsShopElement';
import { useResourcesContext } from 'src/context/ResourcesContext';

export default function CreditsShop() {
  const creditsPrices = useResourcesContext().prices!.CreditsPrices.sort(
    (a, b) => a.price - b.price
  );
  return (
    <div className="tw-grid tw-grid-cols-2 tw-gap-2" key="credits">
      {creditsPrices.map((p) => (
        <CreditsShopElement creditsPrice={p} />
      ))}
    </div>
  );
}
