import { useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Dialogue } from 'src/components/Dialogue/Dialogue';
import { ReportCharacter } from 'src/components/Report/ReportCharacter';
import { EmojiSrc } from 'src/components/Shared/Emoji/Emoji';
import { HornyButtonBG } from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { NoCopyPopup } from 'src/components/Shared/NoCopyPopup/NoCopyPopup';
import { Tag } from 'src/components/Shared/Tag/Tag';
import { appConfig } from 'src/config/app';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { NutakuEnv, useNutakuContext } from 'src/context/Nutaku.Context';
import { useUserContext } from 'src/context/User.context';
import { BotAPI, BotAPIModel } from 'src/services/API/BotAPI';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
import {
  copyURLToClibpoard,
  replaceUserName,
  shareData,
  shareNative,
} from 'src/shared/helpers';
import {
  errorImageSrc,
  formatStory,
  showAuthorization,
} from 'src/shared/utils';
import './index.css';

export type CharacterCarouselSlideProps = {
  character: BotAPIModel;
  onLike: () => any;
  onCreateChat: () => any;
  onView: () => any;
  isCurrentSlide: boolean;
};

export function CharacterCarouselSlide({
  character: {
    BotId,
    Rating,
    CoverUrl,
    Name,
    Author,
    Liked,
    Chatted,
    Viewed,
    LikesCount,
    Tags,
    Story,
    IsBotAuthor,
  },
  onCreateChat,
  onLike,
  onView,
  isCurrentSlide,
}: CharacterCarouselSlideProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { isNutaku, nutakuEnv } = useNutakuContext();
  const [src, setSrc] = useState(CoverUrl);
  const botLinkToShare = !isNutaku
    ? `${window.location.origin}?GirlId=${BotId}`
    : `${appConfig.nutaku.nutakuShareLink}?BotId=${BotId}&CreatorCode=${user?.CreatorCode}`;
  const [shareText, setShareText] = useState(t('Share'));
  const [reportText, setReportText] = useState(t('Report'));

  const interactionRef = useRef<HTMLDivElement>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const viewBotRef = useRef(false);
  const botInteractionInProgress = useRef(false);
  const { addModal, removeModal } = useModalContext();
  const { capture } = useAnalyticsContext();
  const viewBot = () => {
    BotAPI.viewBot(BotId).then(() => onView());
  };
  const [chatClicked, setChatClicked] = useState(false);

  useEffect(() => {
    if (
      !Viewed &&
      imageLoaded &&
      src !== errorImageSrc &&
      isCurrentSlide &&
      !viewBotRef.current
    ) {
      // capture({
      //   event: 'main_bot_view',
      //   data: {
      //     bot_id: BotId,
      //   },
      // });
      if (user) {
        viewBotRef.current = true;
        viewBot();
      }
    }
  }, [user, Viewed, imageLoaded, isCurrentSlide, viewBotRef]);

  const likeBot = () => {
    if (botInteractionInProgress.current) {
      return;
    }

    queryClient.invalidateQueries({
      queryKey: ['Connections'],
      exact: false,
      refetchType: 'all',
    });

    botInteractionInProgress.current = true;
    onLike();

    BotAPI.likeBot({ BotId })
      .then(() => {})
      .finally(() => {
        botInteractionInProgress.current = false;
      });
    // ConnectionsAPI.createChat({ BotId });
  };

  const onReportClick = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    const modal = addModal({
      children: (
        <ReportCharacter
          Id={BotId}
          onReport={() => {
            removeModal(modal);
            setReportText(t('Reported'));
            setTimeout(() => setReportText(t('Report')), 3000);
          }}
        />
      ),
    });
  };

  const createChat = async () => {
    if (botInteractionInProgress.current || chatClicked) {
      return false;
    }

    queryClient.invalidateQueries({
      queryKey: ['Connections'],
      exact: false,
      refetchType: 'all',
    });

    botInteractionInProgress.current = true;
    setChatClicked(true);

    onCreateChat();
    await ConnectionsAPI.getChat({
      BotId: BotId,
      StartFrom: 0,
      PageSize: 20,
    }).then(({ data }) => {
      capture({
        event: 'page_open',
        data: {
          page_name: 'Dialogue',
        },
      });
      addModal({
        children: <Dialogue {...data}></Dialogue>,
        // bg: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${data.Chat.CoverUrl})  center no-repeat`,
      });
      setChatClicked(false);
      botInteractionInProgress.current = false;
      return true;
    });

    // return ConnectionsAPI.createChat({ BotId })
    //   .then(async () => {
    //     onCreateChat();
    //     return await ConnectionsAPI.getChat({
    //       BotId: BotId,
    //       StartFrom: 0,
    //       PageSize: 20,
    //     }).then(({ data }) => {
    //       capture({
    //         event: 'page_open',
    //         data: {
    //           page_name: 'Dialogue',
    //         },
    //       });
    //       addModal({
    //         children: <Dialogue {...data}></Dialogue>,
    //         // bg: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${data.Chat.CoverUrl})  center no-repeat`,
    //       });
    //     });
    //   })
    //   .finally(() => {
    //     setChatClicked(false);
    //     botInteractionInProgress.current = false;
    //     return true;
    //   });
  };

  const shareBotUrl = () => {
    if (nutakuEnv == NutakuEnv.Android) {
      console.log('Sharing with native');
      shareNative({
        title: t('Character') + ' : ' + Name,
        text: t('Want to chat with this AI Character? Try Temptations.AI!'),
        url: botLinkToShare,
        dialogTitle: t('Share this Character!'),
      }).finally(() => {
        capture({
          event: 'share_bot',
          data: {
            bot_id: BotId,
            is_own_bot: IsBotAuthor,
          },
        });
      });
    } else if (nutakuEnv == NutakuEnv.Web) {
      addModal({
        children: (
          <NoCopyPopup
            whatCopying={t('sharing link')}
            textToCopy={botLinkToShare}
          />
        ),
        className: 'horny-bg-dark_opac',
      }).finally(() => {
        capture({
          event: 'share_bot',
          data: {
            bot_id: BotId,
            is_own_bot: IsBotAuthor,
          },
        });
      });
    } else {
      shareData({
        url: botLinkToShare,
        mediaUrl: CoverUrl,
        name: Name,
      }).finally(() => {
        capture({
          event: 'share_bot',
          data: {
            bot_id: BotId,
            is_own_bot: IsBotAuthor,
          },
        });
      });
    }

    copyURLToClibpoard(botLinkToShare);
    setShareText(t('Copied'));
    setTimeout(() => {
      setShareText(t('Share'));
    }, 2000);
  };

  return (
    <div className=" mx-auto w-100 mw-100 position-relative h-100 d-flex justify-content-center">
      <Image
        className="  mx-auto object-fit-cover pe-none"
        src={'/img/Placeholder.svg'}
        style={{
          height: '100%',
          width: window.screen.height / 1.78125,
          maxWidth: '100vw',
        }}
        onLoad={() => {
          if (src !== errorImageSrc) {
            setImageLoaded(true);
          }
        }}
        onError={() => setSrc(errorImageSrc)}
        // style={{ height: '100dvh', minHeight: '100dvh', maxHeight: '100dvh' }}
      />
      <Image
        className="mw-100  mx-auto object-fit-cover position-absolute pe-none object-position-top"
        src={src}
        onLoad={() => {
          if (src !== errorImageSrc) {
            setImageLoaded(true);
          }
        }}
        onError={() => setSrc(errorImageSrc)}
        style={{ height: '100% ', minHeight: '100%', maxHeight: '100%' }}
      />
      <div
        className="position-absolute w-100 h-100 z-1 d-flex flex-column pt-4  justify-content-end carousel-slide_content"
        style={{ top: 0, left: 0 }}
      >
        {/* <div className="h-50"></div> */}
        <div className="d-flex flex-column align-items-end justify-content-between gap-3">
          <div
            className="me-0 ms-auto d-flex flex-column gap-4 px-4 horny-text-shadow "
            ref={interactionRef}
            style={{ width: '70px' }}
          >
            <motion.div
              whileTap={{ scale: 0.8 }}
              className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
              onClick={() =>
                user ? !Liked && likeBot() : showAuthorization(addModal)
              }
            >
              <Icon
                src={Liked ? IconSrc.AddSuper : IconSrc.AddRed}
                size={IconSize.XL}
              />
              <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
                {LikesCount}
              </div>
            </motion.div>
            <motion.div
              whileTap={{ scale: 0.8 }}
              className={
                'cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center ' +
                (chatClicked ? 'pe-none' : '')
              }
              onClick={(e) => {
                if (!user) {
                  return showAuthorization(addModal);
                }
                (e.target as HTMLDivElement).className = 'text-end pe-none';
                createChat().then((res) => {
                  if (res) {
                    (e.target as HTMLDivElement).className = 'text-end ';
                  }
                });
              }}
            >
              <Icon
                src={!chatClicked ? IconSrc.ChatOrange : IconSrc.Loader}
                size={IconSize.XL}
                className={chatClicked ? 'horny-spin' : ''}
              />
              <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
                {t('Chat')}
              </div>
            </motion.div>
            {/* {!isNutaku && ( */}
            <motion.div
              whileTap={{ scale: 0.8 }}
              // data-anal="share_bot"
              // data-anal-bot_id={BotId}
              className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
              onClick={() => {
                // capture({
                //   event: 'share_bot',
                //   data: {
                //     bot_id: BotId,
                //     is_own_bot: IsBotAuthor,
                //   },
                // });
                shareBotUrl();
              }}
            >
              <Icon src={IconSrc.ShareBlue} size={IconSize.XL} />
              <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
                {shareText}
              </div>
            </motion.div>
            {/* )} */}
            <motion.div
              whileTap={{ scale: 0.8 }}
              className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
              onClick={() =>
                user ? onReportClick() : showAuthorization(addModal)
              }
            >
              <Icon src={IconSrc.Report} size={IconSize.XL} />
              <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
                {reportText}
              </div>
            </motion.div>
          </div>
          <div className="text-start d-flex flex-column align-items-start horny-bg-gradient-black_opac pt-5 w-100">
            <div></div>
            <div className="text-start d-flex flex-column gap-2 px-4">
              <div className="fs-2 fw-bold">{Name || 'Jane '}</div>
              <div className="fs-6">
                {t('Author')}:{' '}
                <span className="opacity-75">@{Author || ''}</span>
                {/* <span>
                  <Icon src={IconSrc.Info} size={IconSize.Tiny} />
                </span> */}
              </div>

              <div className="d-flex gap-2">
                {Object.values(Tags).map((tag, i) => (
                  <Tag
                    className="pe-none"
                    // className='d-inline-block'
                    title={t(tag.title as any)}
                    emoji={EmojiSrc.Devil}
                    selected={false}
                    available={true}
                    emojiCode={tag.icon}
                    key={tag.title + i}
                    bg={HornyButtonBG.Red}
                  />
                ))}
              </div>

              <div
                className="hyphens-auto  opacity-75 my-2 fw-light lh-sm"
                lang="en"
              >
                {replaceUserName(formatStory(Story), user!)
                  .split('\\n')
                  .map((line, i) => {
                    return (
                      <p className="mb-2" key={line}>
                        {line}
                      </p>
                    );
                  })}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
