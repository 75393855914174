import { useTranslation } from 'react-i18next';
import { Authorization } from 'src/components/Authorization/Authorization';
import { FindCharacter } from 'src/components/Discover/FindCharacter';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Price } from 'src/components/Shared/Price/Price';
import { UserProfile } from 'src/components/UserProfile/UserProfile';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';
import { HeaderCurrencies } from './HeaderCurrencies';
import './index.css';
import { SubscriptionButton } from './SubscriptionButton';

export type HeaderProps = {
  Energy: number;
};

export type HeaderProperties = {
  showLogin?: boolean;
  showSubscriptionButton?: boolean;
};

export function Header({
  showLogin = true,
  showSubscriptionButton = true,
}: HeaderProperties) {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addModal } = useModalContext();

  return (
    <header
      className="tw-relative tw-z-10 tw-flex tw-justify-end tw-top-0 tw-py-6 tw-ps-0 tw-pe-6"
      style={{ right: 0 }}
    >
      {!user ? (
        showLogin ? (
          <AddBlock
            className="horny-bg-dark_half cursor-pointer"
            onClick={() => {
              addModal({
                children: <Authorization />,
                bg: 'bg-danger',
                className: 'z-50 horny-bg-gradient_red',
              });
            }}
          >
            <div className="fw-bold">
              <span>{t('Log In')}</span>
            </div>
          </AddBlock>
        ) : null
      ) : (
        <div className="tw-flex tw-flex-row tw-gap-2">
          {showSubscriptionButton && <SubscriptionButton />}
          <HeaderCurrencies />
        </div>
      )}
    </header>
  );
}

export function ProfileHeader() {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addModal } = useModalContext();
  return user ? (
    user.IsAnonymous ? (
      <header
        className="position-relative z-1 w-max-content text-start py-6 ps-6 pe-0 d-flex justify-content-end top-0 profile-header tw-shrink-0"
        style={{ left: 0 }}
      >
        <AddBlock
          className="horny-bg-dark_half cursor-pointer"
          onClick={() => {
            addModal({
              children: <Authorization />,
              bg: 'bg-danger',
              className: 'z-50 horny-bg-gradient_red',
            });
          }}
        >
          <div className="fw-bold">
            <span>{t('Log In')}</span>
          </div>
        </AddBlock>
      </header>
    ) : (
      <header
        className="position-relative z-1 w-max-content text-end py-6 ps-6 pe-0 d-flex justify-content-end top-0 profile-header tw-shrink-0"
        style={{ left: 0 }}
      >
        <AddBlock
          className="horny-bg-dark_half cursor-pointer"
          onClick={() => {
            addModal({
              children: <UserProfile user={user} />,
              className: 'horny-bg-dark_opac',
            });
          }}
        >
          <Icon src={IconSrc.Settings} size={IconSize.Small} />
        </AddBlock>
        <AddBlock
          className="horny-bg-dark_half cursor-pointer ms-2"
          onClick={() => {
            addModal({
              children: <FindCharacter />,
              className: 'horny-bg-dark_opac',
            });
          }}
        >
          <Icon src={IconSrc.Find} size={IconSize.Small} />
        </AddBlock>
      </header>
    )
  ) : null;
}

export function CloseHeader(props: HeaderProps) {
  return (
    <header
      className="position-absolute  w-100 text-end py-6 ps-0 pe-6  d-flex justify-content-end top-0 z-10"
      style={{ left: 0 }}
    >
      <AddBlock className="horny-bg-dark_half">
        <Price amount={props.Energy || 0} />
      </AddBlock>
    </header>
  );
}
export type HeaderContainerProps = {
  absolute?: boolean;
};
export function HeaderContainer({ absolute = false }: HeaderContainerProps) {
  return (
    <div
      className={`position-${absolute ? 'absolute' : 'sticky'} d-flex w-100 justify-content-between z-1`}
    >
      <ProfileHeader />
      <Header />
    </div>
  );
}
