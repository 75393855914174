import {
  faArrowRight,
  faCircleCheck,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { showToast } from 'src/components/Shared/Ark/ArkToast';
import { TemptationsButton } from 'src/components/Shared/TemptationsButton';
import { addClickMagicPurchaseCredits } from 'src/context/AnalyticsContext/helpersDOM';
import { useUserContext } from 'src/context/User.context';
import { UserAPI } from 'src/services/API/UserAPI';
import { GetAxiosError } from 'src/services/Utils/GetAxiosError';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import { cn } from 'src/shared/utils';

type EnterCodeState = 'initial' | 'loading' | 'success';

export default function EnterCodePopup() {
  const { t } = useTranslation();
  const [code, setCode] = useState<string | undefined>(undefined);
  const { setUser } = useUserContext();

  const g2aBuyMutation = useMutation({
    mutationFn: (code: string) => UserAPI.buyProductG2A(code),
    onError: (e: AxiosError) => {
      showToast({
        title: t('Error entering code'),
        description: GetAxiosError(e, t('Unknown error occurred')),
        support: true,
      });
      setState('initial');
    },
    onSuccess: (data) => {
      addClickMagicPurchaseCredits(
        data.data.clickmagick_price ? data.data.clickmagick_price : 0
      ).catch(console.error);
      getUserMutation.mutate();
    },
  });

  const getUserMutation = useMutation({
    mutationFn: () => UserAPI.get(),
    onSuccess: (data) => {
      setUser(data.data);
      setState('success');
      setTimeout(() => setState('initial'), 2000);
    },
    onError: () => {
      setState('initial');
    },
  });

  const [state, setState] = useState<EnterCodeState>('initial');

  useEffect(() => {
    if (getUserMutation.isPending || g2aBuyMutation.isPending) {
      setState('loading');
    }
  }, [getUserMutation.isPending, g2aBuyMutation.isPending]);

  return (
    <motion.div
      className="tw-h-full tw-flex tw-items-center tw-px-2"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="tw-self-center tw-px-1 tw-w-full">
        <div className="tw-p-6 tw-flex tw-flex-col tw-gap-4 tw-bg-horny-gray-600  tw-rounded-xl tw-shadow-bezel tw-w-full">
          <div className="tw-text-2xl tw-font-bold">{t('Enter Code')}</div>
          <div>{t('Enter code and add credits to your account')}</div>
          <div className="tw-flex tw-flex-col tw-gap-2 tw-items-center">
            <TextareaAutosize
              className="tw-align-top tw-bg-gradient-to-b tw-from-horny-gray-800 tw-to-horny-gray-700 tw-w-full tw-rounded-md tw-resize-none tw-px-4 tw-py-4 tw-border tw-border-horny-gray-500 tw-shadow-emboss-gray tw-placeholder-horny-gray-100 tw-placeholder-opacity-50 tw-text-horny-gray-100 tw-text-lg"
              onChange={(e) => {
                setCode(e.target.value);
              }}
              placeholder={t('Enter code...')}
              maxRows={1}
            />
            <TemptationsButton
              color="red"
              size="lg"
              onClick={() => {
                code && g2aBuyMutation.mutate(code);
              }}
            >
              <AnimatePresence mode="wait">
                <motion.div
                  className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-min-w-32"
                  key={state}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                >
                  <FontAwesomeIcon
                    icon={
                      state === 'loading'
                        ? faSpinnerThird
                        : state === 'success'
                          ? faCircleCheck
                          : state === 'initial'
                            ? faArrowRight
                            : faArrowRight
                    }
                    className={cn(
                      (g2aBuyMutation.isPending || getUserMutation.isPending) &&
                        'tw-animate-spin'
                    )}
                  />

                  <div className="tw-text-nowrap" key={state + 'text'}>
                    {state === 'loading'
                      ? t('Wait...')
                      : state === 'success'
                        ? t('Activated!')
                        : state === 'initial'
                          ? t('Activate')
                          : t('Activate')}
                  </div>
                </motion.div>
              </AnimatePresence>
            </TemptationsButton>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
