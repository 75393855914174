import {
  faCoinVertical,
  faDoNotEnter,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Offer as OfferType, UpgradeTitle } from 'src/@types/common';
import { DiscountBlock } from 'src/components/Shared/DiscountBlock';
import {
  getIconUrl,
  Icon,
  IconSize,
  IconSrc,
} from 'src/components/Shared/Icon/Icon';
import { TemptationsButton } from 'src/components/Shared/TemptationsButton';
import { useBenefits } from 'src/components/Subscription/Benifits';
import { useNutakuPayment } from 'src/components/Subscription/NutakuPayment';
import { levelIconsSrc } from 'src/components/Subscription/Subscription';
import { UpgradeIcons } from 'src/components/Subscription/Upgrades/Upgrade';
import { useUserContext } from 'src/context/User.context';
import { SubscriptionLevel } from 'src/services/API/ResourcesAPI';
import { nutakuCreatePaymentOptions } from 'src/services/Nutaku/Nutaku';
import { cn } from 'src/shared/utils';

function Offer(offer: OfferType) {
  const { t } = useTranslation();

  const { MappedBenefits } = useBenefits();

  const benifits = MappedBenefits[offer.subscription as SubscriptionLevel];

  const addsUpgrades = offer.offer_upgrades?.filter(
    (upgrade) => upgrade.type === 'adds'
  );
  const includesUpgrades = offer.offer_upgrades?.filter(
    (upgrade) => upgrade.type === 'includes'
  );

  // PAYMENT
  const nutakuPaymentOptions: nutakuCreatePaymentOptions = {
    itemName: t(offer.offer),
    itemId: offer.offer,
    itemPrice: offer.nutaku_price.toString(),
    itemImage: getIconUrl(IconSrc.Offer),
    itemDescription: t(offer.offer),
  };

  const { nutakuCreatePayment } = useNutakuPayment({
    NutakuPayment: nutakuPaymentOptions,
    Type: 'offer',
  });
  // /PAYMENT

  return (
    <div
      className={cn(
        'tw-w-full tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-700 tw-py-6 tw-px-6 tw-rounded-xl tw-shadow-bezel tw-relative tw-overflow-hidden tw-border-[0.5px] tw-border-horny-red-300 tw-border-opacity-50'
      )}
    >
      <DiscountBlock />

      <div className="tw-flex tw-items-center tw-rounded-bl-xl tw-px-4 tw-py-2 tw-bg-horny-red-800 tw-bg-opacity-25 tw-font-bold tw-text-xs tw-text-nowrap tw-w-fit tw-absolute tw-top-0 tw-right-0 tw-leading-none tw-capitalize tw-z-20">
        {offer.discout}
        {t('% discount')}
      </div>

      {/* tw-divide-y tw-divide-opacity-50 tw-divide-horny-red-200 [&>*]:tw-py-4 */}
      <div className="tw-flex tw-flex-col tw-z-20 tw-relative tw-gap-4">
        {/* Top Row */}
        <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center">
          {offer.subscription && (
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-fit tw-gap-2">
              <div className="tw-flex tw-flex-col tw-items-center">
                <Icon
                  src={levelIconsSrc[offer.subscription as SubscriptionLevel]}
                  size={IconSize.XL}
                />
                <div className="tw-opacity-75 tw-font-bold tw-text-xs">
                  {offer.is_yearly ? '1 year' : '1 month'}
                </div>
              </div>
              <div className="tw-items-center tw-font-bold tw-text-3xl tw-leading-none tw-w-fit">
                {t(offer.subscription)}
              </div>
            </div>
          )}
          {offer.credits && offer.credits > 0 && offer.subscription && (
            <FontAwesomeIcon icon={faPlus} className="tw-text-xl" />
          )}
          {offer.credits && offer.credits > 0 && (
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-3xl tw-font-bold">
              <FontAwesomeIcon icon={faCoinVertical} />
              <div>{offer.credits}</div>
            </div>
          )}
        </div>

        {addsUpgrades && addsUpgrades.length > 0 && (
          <div className="tw-flex tw-flex-col tw-gap-2 tw-p-4 tw-bg-horny-red-600 tw-border tw-border-horny-red-400 tw-rounded-lg">
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-xl">
              <FontAwesomeIcon icon={faPlus} className="" />
              <div className="tw-font-bold">{t('upgrades:')}Upgrades</div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-gap-3">
              {addsUpgrades.map((upgrade) => (
                <div
                  className={cn(
                    'tw-flex tw-flex-row tw-gap-2 tw-items-center tw-leading-tight'
                  )}
                >
                  <div className="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-shrink-0 tw-aspect-square">
                    <FontAwesomeIcon
                      icon={UpgradeIcons[upgrade.upgrade as UpgradeTitle]}
                    />
                  </div>
                  <div>{t(upgrade.upgrade)}</div>
                </div>
              ))}
            </div>
          </div>
        )}

        {benifits && (
          <div className="tw-grid tw-grid-cols-2 tw-gap-3">
            {benifits.map((benefit, i) => {
              return (
                <div
                  key={benefit.title}
                  className={cn(
                    'tw-flex tw-items-center tw-gap-2',
                    benefit.disabled && 'tw-opacity-50'
                  )}
                >
                  <FontAwesomeIcon
                    icon={benefit.icon ? benefit.icon : faDoNotEnter}
                    size="xs"
                  />
                  <div
                    className={cn(
                      'tw-text-base',
                      benefit.bold && 'tw-font-bold'
                    )}
                  >
                    {benefit.title ? benefit.title : 'Unavailable'}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {includesUpgrades && includesUpgrades.length > 0 && (
          <div className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center">
            <div className="tw-font-bold tw-pt-2">
              {t('Includes upgrades:')}{' '}
            </div>
            <div className="tw-grid tw-grid-cols-2 tw-gap-3">
              {includesUpgrades.map((upgrade) => (
                <div
                  className={cn(
                    'tw-flex tw-flex-row tw-gap-2 tw-items-center tw-leading-tight'
                  )}
                >
                  <div className="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-shrink-0 tw-aspect-square">
                    <FontAwesomeIcon
                      icon={UpgradeIcons[upgrade.upgrade as UpgradeTitle]}
                    />
                  </div>
                  <div className={cn('tw-opacity-75')}>
                    {t(upgrade.upgrade)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="tw-w-full tw-flex tw-justify-center">
          <TemptationsButton
            color="red"
            size="base"
            onClick={() => {
              nutakuCreatePayment();
            }}
          >
            <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
              <div>Month:</div>
              <img
                src={getIconUrl(IconSrc.NutakuCoin)}
                className="tw-w-6 tw-h-6 tw-shrink-0"
              />
              <div>{offer.nutaku_price}</div>
            </div>
          </TemptationsButton>
        </div>
      </div>
    </div>
  );
}
type OffersProps = {
  onEmptyOffers: () => void;
};
export default function Offers({ onEmptyOffers }: OffersProps) {
  const { user } = useUserContext();
  const offers = user?.Offers;

  if (!offers || offers.length === 0) {
    onEmptyOffers();
    return null;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-3">
      {offers.map((offer, i) => (
        <Offer key={i} {...offer} />
      ))}
    </div>
  );
}
