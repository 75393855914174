import {
  faBroomWide,
  faCommentDots,
  faImage,
  faPen,
  faSliders,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { GetChatResponse } from '@temptations-ai/types';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArkTabs, { Tab } from 'src/components/Shared/Ark/ArkTabs';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import {
  AnimParamInOutOpacityY100,
  AnimParamInOutOpacityYNeg100,
  TransSpringPopup,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { cn } from 'src/shared/utils';
import { ChatAPI } from '../../services/API/ChatAPI';
import { Chat } from '../Chat/Chat';
import {
  DialogueMedia,
  MediaBlock,
} from '../Media/DialogueMedia/DialogueMedia';
import { ChatSettings } from './ChatSettings';
import { ClearChat } from './ClearChat';
import './index.css';
// import { useSearchParams } from 'react-router-dom';
// import { useSearchParams } from 'react-router-dom';

export type DialogueTabType = 'chat' | 'media';
type DialogueTab = Tab & { value: DialogueTabType };

export type DialogueProps = {
  showMedia?: string;
  // chatId: string;
  // Name: string;
  // Author: string;
  // IsBotAuthor: boolean;
};

export function Dialogue({
  showMedia,
  ...chatResponse
}: GetChatResponse & DialogueProps) {
  const { t } = useTranslation();
  // const [searchParams, setSearchParams] = useSearchParams();
  const { capture } = useAnalyticsContext();

  const [selectedTab, setSelectedTab] = useState<DialogueTabType | null>(() => {
    if (showMedia) {
      return 'media';
    }

    return 'chat';
  });
  const [selectedMediaBlock, setSelectedMediaBlock] =
    useState<MediaBlock | null>(() => {
      if (showMedia === 'community') {
        capture({
          event: 'page_open',
          data: {
            page_name: 'Media_community',
          },
        });
        return MediaBlock.Community;
      }
      return MediaBlock.Dialogue;
    });
  const { addModal, removeModal } = useModalContext();
  //   const renderContent = {
  //     [TabToShow.Discover]: () => <CharacterCarousel onLoaded={() => setLoaded(true)} />,
  //     [TabToShow.Connections]: () => <Connections />
  // };

  const switchMediaBlock = (block: MediaBlock) => {
    capture({
      event: 'page_open',
      data: {
        page_name:
          block === MediaBlock.Community ? 'Media_community' : 'Media_my',
      },
    });
    setSelectedMediaBlock(null);
    setSelectedMediaBlock(block);
  };

  const [restartFromMessage, setRestartFromMessage] = useState(false);

  const chatOtherSettingsQuery = useQuery({
    queryKey: ['chatOtherSettings', chatResponse.chat.BotId],
    queryFn: () =>
      ChatAPI.getChatOtherSettings({ BotId: chatResponse.chat.BotId }),
    enabled: true,
    staleTime: Infinity,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const chatSettingsModal = () => {
    const modal = addModal({
      children: (
        <ChatSettings
          botId={chatResponse.chat.BotId}
          settings={chatOtherSettingsQuery.data?.data!} // тут хуево говорить что уже есть данные
          onApply={() => {
            removeModal(modal);
          }}
        />
      ),
    });
  };

  // Change type to Tab but value as DialogueTabType

  const tabs: DialogueTab[] = [
    {
      value: 'chat',
      icon: faCommentDots,
    },
    {
      value: 'media',
      icon: faImage,
    },
  ];

  return (
    <motion.div
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
      className="position-relative m-auto h-100 "
      style={{
        background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${chatResponse.chat.CoverUrl})  center 10% / cover no-repeat`,
      }}
    >
      <div className="dialogue_header position-absolute w-100 z-2 pe-none"></div>
      {
        <div className="tw-absolute tw-z-10 tw-flex-row tw-flex tw-gap-3 tw- tw-justify-between tw-w-full tw-mt-20 tw-px-7 tw-font-bold">
          <ArkTabs
            tabs={tabs}
            defaultTabValue={selectedTab || 'chat'}
            onTabChange={(tabValue) =>
              setSelectedTab(tabValue as DialogueTabType)
            }
          />
          {selectedTab === 'chat' && (
            <motion.div
              className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-justify-end"
              animate="in"
              initial="initial"
              variants={AnimParamInOutOpacityYNeg100}
              transition={TransTweenAnticipate03}
            >
              <motion.div
                className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-cursor-pointer tw-bg-black tw-bg-opacity-50 tw-rounded-md tw-px-2 tw-py-1 tw-w-fit tw-text-sm"
                whileTap={{ scale: 0.9 }}
                onClick={() => setRestartFromMessage((prev) => !prev)}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  className={cn(!restartFromMessage && 'tw-opacity-50')}
                />
                <div className={cn(!restartFromMessage && 'tw-opacity-50')}>
                  {t('Edit')}
                </div>
              </motion.div>
              <motion.div
                className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-cursor-pointer tw-bg-black tw-bg-opacity-50 tw-rounded-md tw-px-2 tw-py-1 tw-w-fit tw-text-sm"
                whileTap={{ scale: 0.9 }}
                onClick={() => {
                  capture({
                    event: 'page_open',
                    data: {
                      page_name: 'Clear_chat',
                    },
                  });
                  addModal({
                    children: <ClearChat id={chatResponse.chat.BotId} />,
                  });
                }}
              >
                <FontAwesomeIcon icon={faBroomWide} />
                <div>{t('Clear')}</div>
              </motion.div>
              <div
                className={cn(
                  'tw-w-5 tw-h-5 tw-shrink-0 tw-aspect-square tw-drop-shadow-md tw-ms-1 tw-cursor-pointer',
                  chatOtherSettingsQuery.isFetching && 'tw-pointer-events-none'
                )}
                onClick={() => chatSettingsModal()}
              >
                <FontAwesomeIcon
                  icon={
                    chatOtherSettingsQuery.isFetching
                      ? faSpinnerThird
                      : faSliders
                  }
                  className={cn(
                    chatOtherSettingsQuery.isFetching && 'tw-animate-spin'
                  )}
                />
              </div>
            </motion.div>
          )}
        </div>
      }
      <motion.div
        animate={selectedTab === 'chat' ? 'in' : 'out'}
        //initial="initial"
        // exit="out"
        variants={AnimParamInOutOpacityYNeg100}
        transition={TransTweenAnticipate03}
        className={`${selectedTab === 'chat' ? 'd-block h-100' : 'd-none'}`}
      >
        <Chat
          chatResponse={chatResponse}
          isRestartFromMessage={restartFromMessage}
          disableRestartFrom={() => setRestartFromMessage(false)}
          isOtherSettingsFetching={chatOtherSettingsQuery.isFetching}
        />
      </motion.div>
      <motion.div
        animate={selectedTab === 'media' ? 'in' : 'out'}
        variants={AnimParamInOutOpacityYNeg100}
        transition={TransTweenAnticipate03}
        className={`${selectedTab === 'media' ? 'd-block h-100' : 'd-none'}`}
      >
        {!selectedMediaBlock ? (
          <div></div>
        ) : selectedMediaBlock === MediaBlock.Dialogue ? (
          <DialogueMedia
            selectedMediaBlock={MediaBlock.Dialogue}
            botId={chatResponse.chat.BotId}
            onSelectMediaBlock={(block) => switchMediaBlock(block)}
          />
        ) : (
          <DialogueMedia
            selectedMediaBlock={MediaBlock.Community}
            botId={chatResponse.chat.BotId}
            onSelectMediaBlock={(block) => switchMediaBlock(block)}
          />
        )}
      </motion.div>
    </motion.div>
  );
}
