import {
  G2ABuyProductResponse,
  LLMMemory,
  LLMModelTitle,
  Offer,
  UserUpgrade,
} from 'src/@types/common';
import { APIBase } from './APIBase';
import { SubscriptionLevel } from './ResourcesAPI';

export enum SubscriptionProvider {
  Patreon = 'Patreon',
  SubscribeStar = 'SubscribeStar',
  Boosty = 'Boosty',
  Card = 'Card',
  G2A = 'G2A',
}

export type SignUpAnonUserCreds = {
  Email: string;
  Password: string;
};

export type EnterCodeRequest = {
  Code: string;
};

export type EnterCodeResponseMessage = {
  Type: 'success' | 'error';
  Message: string;
  Params?: { [key: string]: string };
};

export type EnterCodeResponse = {
  User: UserAPIModel | null;
  Message: EnterCodeResponseMessage;
};

export type UserSubscription = {
  Ends: Date | null;
  Level: SubscriptionLevel | null;
  Provider: SubscriptionProvider | null;
  IsYearly: boolean;
};

export type InviteSubscription = {
  Ends: Date | null;
  Level: SubscriptionLevel | null;
};

export type UserAPIModel = {
  UserId: string;
  Nickname: string;
  Email: string;
  Energy: number;
  MessagesLeft: number;
  SignupReported: boolean;
  Subscription: UserSubscription;
  InviteSubscription: InviteSubscription;
  MergedSubscription: InviteSubscription;
  IsAnonymous: boolean;
  IsGenerateChoices: boolean;
  InvitePoints: number;
  CreatorCode: string;
  LLMModel: LLMModelTitle;
  LLMMemory: LLMMemory;
  Upgrades: UserUpgrade[];
  DaysFromInstall: number;
  Credits: number;
  Offers: Offer[];
};
type UserResponse = UserAPIModel;
//  & {
//     editingCharacter: Character;
// };

export type NutakuUserCreds = {
  nutakuUserId: string;
};

export type SupabaseUserServerResponse = {
  AccessToken: string;
  RefreshToken: string;
  Lifetime: number;
  IsNewUser: string;
};

export class UserAPI extends APIBase {
  static domain = 'user';

  static get() {
    // return this.client.get<UserResponse>('getuser').then((res) => {

    return this.clientV2.get<UserResponse>('v1/user').then((res) => {
      if (res.data !== null) {
        res.data.Energy = Number(res.data.Energy);
        res.data.MessagesLeft = Number(res.data.MessagesLeft);
      }
      if (res.data.IsAnonymous === true) {
        res.data.Nickname = 'Anon-' + res.data.UserId.split('-').reverse()[0];
      }
      return res;
    });
  }

  static changeHandle(handle: string) {
    return this.client.patch<UserResponse>('changeusernickname', {
      Nickname: handle,
    });
  }

  static changeIsGenerateChoices(value: boolean) {
    return this.client.patch<UserResponse>('changeisgeneratechoices', {
      IsGenerateChoices: value,
    });
  }

  static deleteUser() {
    return this.client.delete('deleteuser');
  }

  static addPatreonUser(code: string) {
    return this.client.get('PatreonAddUser', {
      params: {
        code,
      },
    });
  }

  static addSubscribeStarUser(code: string) {
    return this.client.get('SubscribeStarAddUser', {
      params: {
        code,
      },
    });
  }

  static addBoostyUser(email: string) {
    return this.clientV2.put('v1/boosty', {
      email,
    });
    // return this.client.get('BoostyAddUser', {
    //   params: {
    //     email,
    //   },
    // });
  }

  static updateBoostyUser() {
    return this.client.get('BoostyRefreshUser', {});
  }

  static buyProductG2A(code: string) {
    return this.clientV2.post<G2ABuyProductResponse>('v1/g2a', {
      code,
    });
    // return this.client.get('BoostyAddUser', {
    //   params: {
    //     email,
    //   },
    // });
  }

  static reportSignup() {
    return this.client.get('SaveSignupReported');
  }

  static sendRevenue(value: number) {
    return this.client.get('AddMTRevenue?value=' + value);
  }

  static checkUser(email: string) {
    return this.client.get('CheckUserSignUp?email=' + email);
  }

  static signUpAnonUser(creds: SignUpAnonUserCreds) {
    return this.client.put<UserAPIModel>('SignUpAnonUser', creds);
  }

  static changeUserPassword(creds: Pick<SignUpAnonUserCreds, 'Password'>) {
    return this.client.patch('ChangeUserPassword', creds);
  }

  static getSupabaseSessionForNutaku(params: NutakuUserCreds) {
    return this.client.get<SupabaseUserServerResponse>('NutakuAuth', {
      params: {
        Creds: params.nutakuUserId,
      },
    });
  }

  static enterCode(body: EnterCodeRequest) {
    return this.client.post<EnterCodeResponse>('entercode', body);
  }
}
