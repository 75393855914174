import { useEffect, useState } from 'react';
import { MediaAPI, MediaPreviewAPIModel } from 'src/services/API/MediaAPI';
import { MediaBlock, MediaProps } from './DialogueMedia';
import useOnScreen from './useOnScreen';

export enum MediaStatus {
  Init = 'init',
  Idle = 'idle',
  Loading = 'loading',
}

const useMedia = ({
  botId,
  selectedMediaBlock,
}: Omit<MediaProps, 'onSelectMediaBlock'>) => {
  const [media, setMedia] = useState<MediaPreviewAPIModel[] | null>(null);
  const [lastMediumRef, setLastMediumRef] = useState(null);
  const isIntersecting = useOnScreen({ current: lastMediumRef });
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState<MediaStatus>(MediaStatus.Init);
  const resetMedia = () => {
    setMedia(null);
    setLastMediumRef(null);
    setTotal(0);
    setStatus(MediaStatus.Init);
    // useOnScreen({ current: lastMediumRef }))
  };

  useEffect(() => {
    resetMedia();
  }, [selectedMediaBlock]);

  useEffect(() => {
    if (isIntersecting) {
      setStatus(MediaStatus.Loading);
      // console.log('getMediaFromAPI')
      const getMediaFromAPI = {
        [MediaBlock.Dialogue]: () =>
          MediaAPI.getDialogueMedia({
            BotId: botId,
            StartFrom: (media || []).length,
            PageSize: 40,
          }),
        [MediaBlock.Community]: () =>
          MediaAPI.getCommunityMedia({
            BotId: botId,
            StartFrom: (media || []).length,
            PageSize: 40,
          }),
      }[selectedMediaBlock];

      getMediaFromAPI()
        .then((res) => {
          // console.log({res});
          return res;
        })
        .then(({ data }) => {
          setTotal(data.Total);
          setMedia((media || []).concat(data.Media));
          setStatus(MediaStatus.Idle);
        })
        .catch(() => {
          setStatus(MediaStatus.Idle);
        });
      // setMessages(async (old) =>   ConnectionsAPI.getMessages({from: messages.length, size: 10}).then(({data}) => data.messages.concat(old)));
    }
  }, [isIntersecting, selectedMediaBlock]);

  return {
    media,
    setMedia,
    setLastMediumRef,
    total,
    status,
    resetMedia,
  };
};

export default useMedia;
