import { type ClassValue, clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { GetSingleMediaResponse } from 'src/@types/common';
import AudioPlayer from 'src/components/Audio/AudioPlayer';
import { Authorization } from 'src/components/Authorization/Authorization';
import MediaDisplayPhotoInner from 'src/components/Media/Shared/MediaDisplay/MediaDisplayPhotoInner';
import {
  MediaDisplayWrapper,
  MediaStatusChangeType,
} from 'src/components/Media/Shared/MediaDisplay/MediaDisplayWrapper';
import { AddModalFunc } from 'src/context/Modal.Context';
import { MediaPreviewAPIModel, MediaType } from 'src/services/API/MediaAPI';
import { twMerge } from 'tailwind-merge';
import { isIOS } from './helpers';

export type MediaControls = {
  addMediaToList: (media: MediaPreviewAPIModel) => any;
  removeMediaFromList: (id: string) => any;
};
export const splitTextLines = (text: string = ''): JSX.Element[] => {
  return text.split('\n').map((line) => {
    return (
      <p className="mb-0" key={line}>
        {line}
      </p>
    );
  });
};

export const splitTextLinesInline = (text: string = ''): JSX.Element[] => {
  return text.split('\n').map((line, i, lines) => {
    return (
      <span className="mb-0" key={line}>
        {line} {i < lines.length - 1 && <br />}
      </span>
    );
  });
};

export const formatText = (text: string = '') => {
  const splittedText = text.split('"');
  return splittedText.map((str, i) => {
    return i % 2 === 0 ? (
      <i className="" key={str + i} style={{ opacity: 0.7 }}>
        {!str || str === ' ' ? '' : '*'}
        {splitTextLinesInline(str)}
        {!str || str === ' ' ? '' : '*'}
      </i>
    ) : (
      <span className="pb-1" key={str + i}>
        {' '}
        {splitTextLinesInline(str)}{' '}
      </span>
    );
  });
};

export const removeBraces = (text: string) => {
  return text.replace(/\"/g, '');
};

export const formatStory = (text: string = '') => {
  text = text || '';
  if (text.startsWith('" ')) {
    return removeBraces(text.slice(2));
  }
  return removeBraces(text);
};

export const ellipseSliceString = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength).padEnd(maxLength + 3, '.');
};

export const sleep = (value: number) =>
  new Promise((resolve) => setTimeout(resolve, value));

export const errorImageSrc = 'img/Error.svg';

export const underlineSelected = (
  <div
    className="m-auto"
    style={{ width: 25, height: 2, backgroundColor: 'white' }}
  ></div>
);

export const focusEditableElement = (ref: React.RefObject<any>) => {
  if (isIOS()) {
    ref.current.click();
    return;
  }
  if (ref.current) {
    const el = ref.current;
    const selection = window.getSelection() as Selection;
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(el);
    range.collapse(false);
    selection.addRange(range);
    el.focus();
  }
};

export const getMediaDisplay = (
  data: GetSingleMediaResponse,
  type: MediaType,
  onChange?: (status: MediaStatusChangeType) => void
) => {
  return {
    [MediaType.Photo]: (data: GetSingleMediaResponse) => (
      <MediaDisplayWrapper
        {...data}
        children={<MediaDisplayPhotoInner src={data.CoverUrl} />}
        childrenType="photo"
        onChange={onChange}
      />
    ),
    [MediaType.Video]: (data: GetSingleMediaResponse) => (
      <MediaDisplayWrapper
        {...data}
        children={<div>Hello</div>}
        childrenType="audio"
        onChange={onChange}
      />
    ),
    [MediaType.Audio]: (data: GetSingleMediaResponse) => (
      <MediaDisplayWrapper
        {...data}
        children={<AudioPlayer url={data.CoverUrl} />}
        childrenType="audio"
        onChange={onChange}
      />
    ),
  }[type];
};

export const showAuthorization = (addModal: AddModalFunc) => {
  return addModal({
    children: <Authorization />,
    className: 'horny-bg-gradient_red',
  });
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
