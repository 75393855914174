import { motion } from 'framer-motion';
import { cn } from 'src/shared/utils';

type Color = 'red' | 'gray';
const Colors: Record<Color, string> = {
  red: 'tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600',
  gray: 'tw-bg-gradient-to-b tw-from-horny-gray-700 tw-to-horny-gray-900',
};

type Size = 'base' | 'lg' | 'xs';
const Sizes: Record<Size, string> = {
  base: 'tw-px-6 tw-py-3 tw-text-lg tw-rounded-full',
  lg: 'tw-px-8 tw-py-4 tw-text-xl tw-rounded-3xl',
  xs: 'tw-px-4 tw-py-2 tw-text-xs tw-rounded-full',
};

type Width = 'fit' | 'full';
const Widths: Record<Width, string> = {
  fit: 'tw-w-fit',
  full: 'tw-w-full',
};

type TemptationsButtonProps = {
  color: Color;
  size: Size;
  children?: React.ReactNode;
  width?: Width;
  onClick?: () => void;
  className?: string;
  animate?: boolean;
};

export function TemptationsButton({
  color,
  size,
  children,
  onClick,
  width = 'fit',
  className,
  animate = true,
}: TemptationsButtonProps) {
  return (
    <motion.button
      className={cn(
        'tw-shadow-bezel tw-font-bold tw-w-fit',
        Colors[color],
        Sizes[size],
        Widths[width],
        className
      )}
      whileTap={animate ? { scale: 0.85 } : undefined}
      onClick={onClick}
    >
      {children || 'Button'}
    </motion.button>
  );
}
