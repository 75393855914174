import { Image, ImageProps } from 'react-bootstrap';

import { EmojiSrc } from '../Emoji/Emoji';
import './index.css';

export enum IconSrc {
  Add = 'Add',
  Add1 = 'Add1',
  AddRed = 'AddRed',
  Brain = 'Brain',
  Audio = 'Audio',
  Audio1 = 'Audio1',
  Back = 'Back',
  Chat = 'Chat',
  ChatOrange = 'ChatOrange',
  Copy = 'Copy',
  Card = 'card',
  Discord = 'Discord',
  Twitter = 'Twitter',
  Download = 'Download',
  DownloadUpdated = 'DownloadUpdated',
  Edit = 'Edit',
  Energy = 'Energy',
  First = 'First',
  Google = 'Google',
  Info = 'Info',
  Link = 'Link',
  Loader = 'Loader',
  Magic = 'Magic',
  MagicRed = 'MagicRed',
  Next = 'Next',
  NoSound = 'NoSound',
  Pause = 'Pause',
  Photo = 'Photo',
  Reload = 'Reload',
  Save = 'Save',
  Settings = 'Settings',
  Teamwork = 'Teamwork',
  Unlock = 'Unlock',
  check = 'check',
  checkRed = 'checkRed',
  dots = 'dots',
  exit = 'exit',
  hide = 'hide',
  send = 'send',
  send_orange = 'send_orange',
  share = 'share',
  show = 'show',
  Play = 'Play',
  Video = 'Video',
  Discover = 'Discover',
  Community = 'Community',
  SubscribeStar = 'SubscribeStar',
  Patreon = 'Patreon',
  Boosty = 'Boosty',
  AddSuper = 'AddSuper',
  ShareBlue = 'shareBlue',
  Shape = 'Shape',
  NoAds = 'No Ads',
  Time = 'Time',
  Up = 'Up',
  SubscriptionBoyfriend = 'subscription/SubscriptionBoyfriend',
  SubscriptionBoss = 'subscription/SubscriptionBoss',
  SubscriptionDaddy = 'subscription/SubscriptionDaddy',
  SubscriptionMaster = 'subscription/SubscriptionMaster',
  Circle = 'Circle',
  Report = 'Report',
  Clear = 'Clear',
  Language = 'Language',
  Warning = 'Warning',
  Email = 'email',
  EmailConfigured = 'email_confirmed',
  Premium = 'Premium',
  Friend = 'Friend',
  Boyfriend = 'Boyfriend',
  Daddy = 'Daddy',
  Boss = 'Boss',
  NutakuCoin = 'NutakuCoin',
  NutakuLogo = 'NutakuLogo',
  Web = 'Web',
  Android = 'Android',
  Find = 'Find',
  Top = 'Top',
  Invite = 'Invite',
  Target = 'Target',
  MedalGold = 'MedalGold',
  MedalSilver = 'MedalSilver',
  MedalBronze = 'MedalBronze',
  en = 'flag/united kingdom',
  ru = 'flag/russia',
  de = 'flag/germany',
  fr = 'flag/france',
  it = 'flag/italy',
  es = 'flag/spain',
  pt = 'flag/brazil',
  pl = 'flag/poland',
  tr = 'flag/turkey',
  ja = 'flag/japan',
  zh = 'flag/china',
  ko = 'flag/south korea',
  FourthToTen = 'FourthToTen',
  GeneralUp = 'GeneralUp',
  MemoryUp = 'MemoryUp',
  ControlUp = 'ControlUp',
  AdvancedUp = 'AdvancedUp',
  G2A = 'g2a',
  CreditsCoin = 'CreditsCoin',
  Offer = 'Offer',
}

export const SubscriptionIcons = {
  Boss: IconSrc.Boss,
  Daddy: IconSrc.Daddy,
  Boyfriend: IconSrc.Boyfriend,
  Friend: IconSrc.Friend,
  Master: IconSrc.SubscriptionMaster,
};

export enum IconState {
  Processing = 'processing',
}

export enum IconSize {
  Micro = 'micro',
  Tiny = 'tiny',
  XSmall = 'xs',
  Small = 'small',
  Smaller = 'smaller',
  Medium = 'medium',
  Big = 'big',
  XL = 'xl',
}

export type IconProps = {
  src: IconSrc | EmojiSrc;
  state?: IconState;
  size?: IconSize;
} & Partial<ImageProps>;
export function Icon({
  src,
  className = '',
  state,
  size,
  ...htmlProps
}: IconProps) {
  // const imgSource = `${process.env.REACT_APP_CDN_HOST}/img/icon/${src}.png`;
  const imgSource = getIconUrl(src as IconSrc);
  const sizeClass = size ? `horny-icon_${size}` : '';
  return (
    <Image
      className={`horny-icon d-inline-block ${className} ${sizeClass} ${state ? `horny-icon_${state}` : ''}`}
      src={imgSource + '?width=75'}
      {...htmlProps}
    ></Image>
  );
}

export const getIconUrl = (src: IconSrc) =>
  `${process.env.REACT_APP_CDN_HOST}/img/icon/${src}.png`;
