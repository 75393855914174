import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ImageGenerationModelType, MediaPrompt } from 'src/@types/common';
import {
  ShowSubscriptionsReason,
  Subscriptions,
} from 'src/components/Subscription/Subscriptions';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';
import { cn } from 'src/shared/utils';

export type PredefinedPromptElementProps = {
  prompt: MediaPrompt;
  isSelected: boolean;
  model: ImageGenerationModelType;
  onSelect: () => any;
};
export default function PredefinedPromptElement({
  model,
  prompt,
  isSelected,
  onSelect,
}: PredefinedPromptElementProps) {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addModal } = useModalContext();
  return (
    <motion.div
      className={cn(
        'tw-aspect-square tw-bg-contain tw-bg-no-repeat tw-bg-center tw-rounded-md tw-relative tw-overflow-hidden tw-flex tw-flex-col tw-justify-end tw-shadow',
        isSelected
          ? 'tw-border tw-border-horny-red-600 tw-pointer-events-none'
          : 'tw-border tw-border-horny-gray-700 tw-cursor-pointer'
      )}
      style={{
        backgroundImage: `url('${prompt.Image[model]}?width=250')`,
      }}
      onClick={() => {
        if (prompt.IsPremium && user?.MergedSubscription.Level === null) {
          return addModal({
            children: (
              <Subscriptions
                source="pose_premium"
                showReason={ShowSubscriptionsReason.Premium}
              />
            ),
            showSubscriptionButton: false,
          });
        }
        onSelect();
      }}
      whileTap={{ scale: 0.9 }}
    >
      {prompt.IsPremium && user?.MergedSubscription.Level === null && (
        <div className="tw-absolute tw-top-0 tw-right-0 tw-flex tw-flex-row tw-items-center tw-gap-1 tw-bg-black tw-bg-opacity-50 tw-text-xxs tw-rounded-bl-md tw-px-2 tw-py-1 tw-font-bold tw-leading-none">
          <div>{t('Premium')}</div>
          <FontAwesomeIcon icon={faLockKeyhole} />
        </div>
      )}
      <div className="tw-text-xs tw-leading-tight tw-w-full tw-p-2 tw-h-2/3 tw-flex tw-items-end tw-bg-gradient-to-b tw-from-transparent tw-to-black/75 tw-text-shadow tw-font-bold">
        {t(prompt.Title)}
      </div>
    </motion.div>
  );
}
