import {
  faCamera,
  faVideo,
  faVolume,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { TemptationsButton } from 'src/components/Shared/TemptationsButton';
import { MediaType } from 'src/services/API/MediaAPI';
import { cn } from 'src/shared/utils';

export type MediaFilterProps = {
  selectedType: MediaType | null;
  onSelect: (type: MediaType | null) => any;
};

const mappedIcons: Record<MediaType, IconDefinition> = {
  [MediaType.Photo]: faCamera,
  [MediaType.Video]: faVideo,
  [MediaType.Audio]: faVolume,
};

export function MediaFilter({ selectedType, onSelect }: MediaFilterProps) {
  const { t } = useTranslation();
  return (
    <div className="tw-flex tw-flex-row tw-gap-1">
      {Object.keys(MediaType)
        .filter((key) => Number.isNaN(Number(key)))
        .map((mediaType) => {
          const mediaTypeToProcess: keyof typeof MediaType =
            mediaType as keyof typeof MediaType;
          return (
            <div
              key={mediaType}
              className={cn(
                MediaType[mediaTypeToProcess] !== selectedType &&
                  MediaType[mediaTypeToProcess] !== MediaType.Video &&
                  'tw-opacity-50 ',
                MediaType[mediaTypeToProcess] === MediaType.Video &&
                  'tw-opacity-50 tw-pointer-events-none',
                MediaType[mediaTypeToProcess] === selectedType &&
                  'tw-pointer-events-none'
              )}
            >
              <TemptationsButton
                color={
                  MediaType[mediaTypeToProcess] !== MediaType.Video
                    ? 'red'
                    : 'gray'
                }
                size="xs"
                onClick={() => {
                  onSelect(
                    MediaType[mediaTypeToProcess] === selectedType
                      ? null
                      : MediaType[mediaTypeToProcess]
                  );
                }}
              >
                <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                  <FontAwesomeIcon
                    icon={mappedIcons[MediaType[mediaTypeToProcess]]}
                  />
                  <div>{t(mediaTypeToProcess as any)}</div>
                </div>
              </TemptationsButton>
            </div>
          );
        })}
    </div>
  );
}
