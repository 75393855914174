import { useState } from 'react';
import { ArkSelect, ArkSelectItem } from '../Shared/Ark/ArkSelect';
import AudioPlayer from './AudioPlayer';

export type ArkSelectItemWithVoicePreview = ArkSelectItem & {
  preview_url: string;
};

type VoiceSwitcherProps = {
  onChange: (voice_id: string) => any;
  defaultVoice: string;
  voices: ArkSelectItemWithVoicePreview[];
};

export default function VoiceSwitcher({
  voices,
  defaultVoice,
  onChange,
}: VoiceSwitcherProps) {
  const [selectedVoice, setSelectedVoice] =
    useState<ArkSelectItemWithVoicePreview>(voices[0]);

  return (
    <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
      <ArkSelect
        onChange={(e) => {
          setSelectedVoice(voices.find((v) => v.value === e)!);
          onChange(e);
        }}
        items={voices}
        defaultValue={defaultVoice}
      />
      <AudioPlayer url={selectedVoice.preview_url} isWaveHidden={true} />
    </div>
  );
}
