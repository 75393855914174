import { motion } from 'framer-motion';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { TransSpringPopup } from 'src/shared/animations';

export function FullSizeLoader() {
  return (
    <motion.div
      className="position-fixed horny-absolute_full d-flex   align-items-center justify-content-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={TransSpringPopup}
      // style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      <div className="horny-bg-dark_half rounded-3 p-10">
        <Icon src={IconSrc.Loader} size={IconSize.XL} className="horny-spin" />
      </div>
    </motion.div>
  );
}
