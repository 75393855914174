import { ArkSliderValueType } from 'src/components/Shared/Ark/ArkSlider';
import { create } from 'zustand';

interface AudioStore {
  volume: ArkSliderValueType;
  setVolume: (volume: ArkSliderValueType) => void;
  currentPlayingId: string | null;
  setCurrentPlayingId: (id: string | null) => void;
}

export const useAudioStore = create<AudioStore>((set) => ({
  volume: 50,
  setVolume: (volume) => set({ volume }),
  currentPlayingId: null,
  setCurrentPlayingId: (id) => set({ currentPlayingId: id }),
}));
