import axios, { AxiosError, AxiosInstance } from 'axios';
 
const BASE_URL = process.env.REACT_APP_API_HOST; //'http://hornyai-stage.azurewebsites.net/api'; //
const BASE_URL_V2 = process.env.REACT_APP_API_HOST_V2; //'http://hornyai-stage.azurewebsites.net/api'; //

const axiosInstance = axios.create({ baseURL: BASE_URL });
axios.defaults.withCredentials = true;

const axiosInstanceV2 = axios.create({ baseURL: BASE_URL_V2,});
axios.defaults.withCredentials = true;

// axios.defaults.headers['x-patreon-signature'] =
//   'bfa3d5574948daf19a7f279d422c1c8e';

export const getAxiosInstance = (): AxiosInstance => {
  return axiosInstance;
};

export const getAxiosInstanceV2 = (): AxiosInstance => {
  //  axiosInstanceV2.defaults.headers.client_version = appConfig.app.version;

  return axiosInstanceV2;
};

export const ifPaymentError = (error: unknown): boolean => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    if (axiosError.response && axiosError.response.status === 402) {
      return true;
    }
  }
  return false;
};
