import { HTMLAttributes, ReactElement } from 'react';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useModalContext } from 'src/context/Modal.Context';
import { Header } from '../Header/Header';
import './index.css';
export enum ModalType {
  Auth = 'auth',
}
export type ModalProps = {
  children: ReactElement;
  bg?: string;
  timestamp: number;
  onClose?: () => any;
  showBalance?: boolean;
  isFullscreen?: boolean;
  isNoHeader?: boolean;
  showSubscriptionButton?: boolean;
} & Partial<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>;

export function Modal({
  children,
  bg = '',
  timestamp,
  className = '',
  onClose,
  showBalance = true,
  isFullscreen = false,
  isNoHeader = false,
  showSubscriptionButton = true,
}: ModalProps) {
  const { removeModal } = useModalContext();
  const bgClass = bg.startsWith('bg-') ? bg : '';
  return (
    <div
      className={`horny-modal position-fixed  z-3  h-100 w-100 top-0    d-flex justify-content-center  ${bgClass} ${className}`}
      style={{
        bottom: 0,
        ...(!isFullscreen ? { maxWidth: '600px' } : {}),
        ...(bg ? { background: bg } : {}),
        backgroundSize: 'cover',
      }}
    >
      {!isNoHeader && (
        <div className="position-absolute d-flex w-100 justify-content-between z-10 header-container m-auto">
          <div
            className="w-max-content text-left position-relative z-10 mt-6 ms-6 cursor-pointer"
            onClick={() => {
              removeModal(timestamp);
              onClose && onClose();
            }}
          >
            <AddBlock className="horny-bg-dark_half">
              <Icon src={IconSrc.exit} size={IconSize.Small} />
            </AddBlock>
          </div>
          {showBalance && <Header showLogin={false} showSubscriptionButton={showSubscriptionButton} />}
        </div>
      )}
      <div
        className="text-center w-100  p-0 main_container mx-auto h-100  "
        style={{ maxWidth: 600 }}
      >
        {children}
      </div>
    </div>
  );
}
