import { Voice } from '@temptations-ai/types';
import { useResourcesContext } from '../../context/ResourcesContext';
import { ArkSelectItemWithVoicePreview } from './VoiceSwitcher';

export function useVoices(): ArkSelectItemWithVoicePreview[] {
  const { mediaPrompts } = useResourcesContext();

  const voices = mediaPrompts?.Voices.map((voice: Voice) => ({
    label: voice.title,
    value: voice.voice_id,
    preview_url: voice.preview_url,
  }))!;

  return voices;
}
