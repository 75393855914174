import { Icon, IconSrc } from '../Icon/Icon';

export type LinkProps = {
  url: string;
  // text: string;
  icon?: IconSrc;
  onClick?: () => any;
  underlined?: boolean;
  children?: JSX.Element | string;
} & Partial<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
>;
export function Link({
  url,
  icon,
  children,
  underlined = true,
  target,
  onClick,
}: LinkProps) {
  return (
    <a
      className="d-inline-flex align-items-end gap-2 text-nowrap"
      href={url}
      rel="noreferrer"
      target={target || '_blank'}
      {...(onClick ? { onClick } : {})}
    >
      {icon && <Icon src={icon} />}
      <div
        className={`text-white fw-bold text-decoration-${underlined ? 'underline' : 'none'}`}
      >
        <b>{children}</b>
      </div>
    </a>
  );
}
