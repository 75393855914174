import { faTurnLeft, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { TemptationsButton } from 'src/components/Shared/TemptationsButton';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';

type WillDeleteAudioPupupProps = {
  onOk: () => any;
  onCancel: () => any;
};
export function WillDeleteAudioPupup({
  onOk,
  onCancel,
}: WillDeleteAudioPupupProps) {
  const { t } = useTranslation();
  return (
    <motion.div
      className="tw-h-full tw-flex tw-items-center tw-px-2"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      {/* text-center  */}
      <div className="tw-self-center tw-px-1 tw-w-full">
        <div className="tw-p-6 tw-flex tw-flex-col tw-gap-4 tw-bg-horny-gray-600  tw-rounded-xl tw-shadow-bezel tw-w-full">
          <div className="tw-text-2xl tw-font-bold">
            {t('Audio will be deleted')}
          </div>
          <div>{t('Audio will be deleted description')}</div>
          <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
            <TemptationsButton
              color="gray"
              size="base"
              onClick={() => {
                onCancel();
              }}
            >
              <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                <FontAwesomeIcon icon={faTurnLeft} />
                <div>{t('Cancel')}</div>
              </div>
            </TemptationsButton>
            <TemptationsButton
              color="red"
              size="base"
              onClick={() => {
                onOk();
              }}
            >
              <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                <FontAwesomeIcon icon={faWarning} />
                <div>{t('Ok, regenerate')}</div>
              </div>
            </TemptationsButton>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
