import {
  BotTextRequest,
  BotTextResponse,
  ChatMessage,
  ChatOtherSettings,
  DeleteMessagesAfterMessageIdRequest,
  DeleteMessagesAfterMessageIdResponse,
  GetChatOtherSettingsRequest,
  MessageAddAudioRequest,
  RefetchMessagesRequest,
  RefetchMessagesResponse,
  RegenerateMessageRequst,
  RegenerateMessageResponseV2,
  RegeneratePhotoRequest,
  RegeneratePhotoResponse,
  RequestPhotoRequest,
  RequestPhotoResponse,
  UpdateChatOtherSettingsRequest,
  UserReplyRequest,
  UserReplyResponse,
  updateLLMMemory,
  updateLLMModel,
} from 'src/@types/common';
import { APIBase } from './APIBase';
import { NudityLevel } from './ResourcesAPI';

export type RequestMediaRequestV2 = {
  BotId: string;
  PromptId: string;
  NudityLevel: NudityLevel;
};

export type RegenerateImageRequstV2 = {
  BotId: string;
  MessageId: string;
};

export class ChatAPI extends APIBase {
  static restartFromMessage(params: DeleteMessagesAfterMessageIdRequest) {
    return this.clientV2.delete<DeleteMessagesAfterMessageIdResponse>(
      'v1/chat/delete_after_message_id',
      { params }
    );
  }
  static userReply(params: UserReplyRequest) {
    return this.clientV2.put<UserReplyResponse>('v1/chat/user_reply', params);
  }

  static botText(params: BotTextRequest) {
    return this.clientV2.put<BotTextResponse>('v1/chat/bot_text', params);
  }

  static regenerateBotText(params: RegenerateMessageRequst) {
    return this.clientV2.patch<RegenerateMessageResponseV2>(
      'v2/chat/bot_text_regenerate',
      params
    );
  }

  static requestPhoto(data: RequestPhotoRequest) {
    return this.clientV2.put<RequestPhotoResponse>(
      'v1/chat/request_photo',
      data
    );
  }

  static regeneratePhoto(data: RegeneratePhotoRequest) {
    return this.clientV2.patch<RegeneratePhotoResponse>(
      'v1/chat/regenerate_photo',
      data
    );
  }

  static refetchMessages(data: RefetchMessagesRequest) {
    return this.clientV2.get<RefetchMessagesResponse>(
      'v1/chat/refetch_messages',
      {
        params: data,
      }
    );
  }

  static updateLLMMemory(data: updateLLMMemory) {
    return this.clientV2.patch<boolean>('v1/chat/chat_settings/memory', data);
  }

  static updateLLMModel(data: updateLLMModel) {
    return this.clientV2.patch<boolean>(
      'v1/chat/chat_settings/llm_model',
      data
    );
  }

  static messageAddAudio(data: MessageAddAudioRequest) {
    return this.clientV2.patch<ChatMessage>('v1/chat/message_add_audio', data);
  }

  static updateChatOtherSettings(data: UpdateChatOtherSettingsRequest) {
    return this.clientV2.patch<ChatOtherSettings>(
      'v1/chat/chat_settings/other',
      data
    );
  }

  static getChatOtherSettings(data: GetChatOtherSettingsRequest) {
    return this.clientV2.get<ChatOtherSettings>('v1/chat/chat_settings/other', {
      params: data,
    });
  }
}
