import { motion } from 'framer-motion';
import { HTMLAttributes, Ref } from 'react';
import { Icon, IconProps, IconSize } from '../Icon/Icon';
import './index.css';
export enum HornyButtonBG {
  Red = 'red',
  Orange = 'orange',
}
export enum HornyButtonHeight {
  Short = 25,
  Shorter = 34,
  Medium = 40,
  Long = 41,
  Tall = 50,
  InviteShop = 42,
}

export enum HornyButtonType {
  Big = 'big',
  Chat = 'chat',
}
export type HornyButtonProps = {
  shadow?: string;
  className?: string;
  onClick: (...args: any) => any;
  text?: string;
  height?: HornyButtonHeight;
  bg?: HornyButtonBG;
  // type?: HornyButtonType;
  icon?: IconProps['src'];
  disabled?: true;
  // children?: ReactElement<any, any> | ReactElement<any, any>[] | string;
  iconSize?: IconSize;
  ref?: Ref<any>;
} & Partial<
  React.DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>;

export function HornyButton({
  shadow = 'horny-shadow',
  className = '',
  style,
  children,
  height = HornyButtonHeight.Medium,
  iconSize,
  text,
  onClick,
  icon,
  bg = HornyButtonBG.Red,
  disabled,
  ...otherAttrs
}: HornyButtonProps) {
  const disabledClassName = disabled ? 'horny-disabled' : '';
  const heightClasses = {
    [HornyButtonHeight.Short]: 'short horny-text_tiny rounded-5',
    [HornyButtonHeight.Shorter]: 'shorter',
    [HornyButtonHeight.Medium]: 'middle',
    [HornyButtonHeight.Tall]: 'tall fs-5',
    [HornyButtonHeight.Long]: 'long',
    [HornyButtonHeight.InviteShop]: 'invite-shop',
  };

  const gapClass = {
    [HornyButtonHeight.Short]: 'gap-1',
    [HornyButtonHeight.Shorter]: 'gap-2',
    [HornyButtonHeight.Medium]: 'gap-2',
    [HornyButtonHeight.Long]: 'gap-2',
    [HornyButtonHeight.Tall]: 'gap-3',
    [HornyButtonHeight.InviteShop]: 'gap-2',
  }[height];

  const iconSizeProp =
    iconSize ||
    {
      [HornyButtonHeight.Short]: IconSize.XSmall,
      [HornyButtonHeight.Shorter]: IconSize.XSmall,
      [HornyButtonHeight.Medium]: IconSize.XSmall,
      [HornyButtonHeight.Long]: IconSize.XSmall,
      [HornyButtonHeight.Tall]: IconSize.Medium,
      [HornyButtonHeight.InviteShop]: IconSize.Medium,
    }[height];

  const heightClass = !height ? '' : 'horny-button_' + heightClasses[height];

  return (
    <motion.button
      whileTap={{ scale: 0.8 }}
      className={`horny-button d-flex flex-row align-items-center horny-mw_control ${shadow} horny-bg-gradient_${bg} ${heightClass}  text-white fw-bold ${className} border-0 text-left ${disabledClassName} px-3`}
      onClick={onClick}
      style={{ ...(style || {}) }}
      //{...otherAttrs}
    >
      <div
        className={`d-flex w-100 justify-content-between align-content-center align-items-center ${gapClass}`}
      >
        {/* <div className={`d-flex justify-content-start ${gapClass}  align-items-center fw-bold`}> */}
        {(icon || text) && (
          <div className={`d-flex ${gapClass} align-items-center`}>
            {icon && <Icon src={icon} className="" size={iconSizeProp} />}
            {text}
          </div>
        )}
        {children && children}
      </div>
      {/* </div>  */}
    </motion.button>
  );
}
