import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../context/User.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { useModalContext } from '../../../context/Modal.Context';
import { Authorization } from '../../Authorization/Authorization';
import { Subscriptions } from '../../Subscription/Subscriptions';

export function SlowDown(){
    const { user, setUser } = useUserContext();
    const {addModal} = useModalContext();
    const { t } = useTranslation();
    if ((user?.DaysFromInstall && user!.DaysFromInstall < 3) || user?.Subscription.Level !== null){
        return null;
    }
    return <div className='tw-flex align-items-center tw-text-xs tw-gap-2'>
        <div className='tw-flex tw-gap-1 align-items-center'>
            <FontAwesomeIcon icon={faClock}/>
            <div>{t('Slow generation')}</div>
        </div>
        <div className='tw-underline cursor-pointer' onClick={() => {
              addModal({
                children: user.IsAnonymous ? (
                  <Authorization />
                ) : (
                  <Subscriptions source="slow_down_messages" />
                ),
                className: user.IsAnonymous
                  ? 'z-50 horny-bg-gradient_red'
                  : 'horny-bg-dark_opac',
                showBalance: false,
                showSubscriptionButton: false,
              });
            }}
        >
            {t('Subcribe to speed up')}
        </div>
    </div>
}