import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { cn } from 'src/shared/utils';

export type CustomTextAreaProps = {
  onChange: (value: string, isMaxLength: boolean) => any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => any;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => any;
  placeholder: string;
  maxRows?: number;
  value: string;
  color: 'orange' | 'gray';
  className?: string;
  maxLength?: number;
};

export function CustomTextArea({
  onChange,
  onKeyDown,
  onKeyUp,
  placeholder,
  value,
  color,
  maxRows = 1,
  className,
  maxLength,
}: CustomTextAreaProps) {
  const [maxLengthPercentage, setMaxLengthPercentage] = useState(0);
  const [currentLength, setCurrentLength] = useState(0);

  const changeMaxLengthPercentage = (currentLength: number) => {
    if (!maxLength) return setMaxLengthPercentage(0);
    const percentage = (currentLength / maxLength) * 100;
    if (percentage > 100) return setMaxLengthPercentage(100);
    return setMaxLengthPercentage(percentage);
  };

  return (
    <div
      className={cn(
        'tw-grid tw-rounded-md tw-overflow-hidden tw-border',
        maxLengthPercentage === 100
          ? 'tw-border-horny-red-500'
          : 'tw-border-horny-orange-700'
      )}
    >
      <TextareaAutosize
        className={cn(
          'tw-row-start-1 tw-col-start-1',
          'tw-w-full tw-resize-none tw-p-5',
          className,
          color === 'orange' &&
            'tw-bg-gradient-to-b tw-from-horny-orange-300 tw-to-horny-orange-200 tw-text-horny-orange-700 tw-shadow-emboss-orange tw-placeholder-horny-orange-700 tw-placeholder-opacity-50'
        )}
        onChange={(e) => {
          onChange(
            e.target.value,
            maxLength ? e.target.value.length >= maxLength : false
          );
          changeMaxLengthPercentage(e.target.value.length);
          setCurrentLength(e.target.value.length);
        }}
        value={value}
        onKeyDown={(e) => {
          onKeyDown?.(e);
        }}
        onKeyUp={(e) => onKeyUp?.(e)}
        placeholder={placeholder}
        maxRows={maxRows}
      />
      <div className="tw-row-start-1 tw-col-start-1 tw-h-full tw-w-full tw-flex tw-pointer-events-none tw-items-end">
        <div
          className={cn(
            'tw-h-[1px]',
            maxLengthPercentage === 100
              ? 'tw-bg-horny-red-500'
              : 'tw-bg-horny-orange-700'
          )}
          style={{ width: `${maxLengthPercentage}%` }}
        ></div>
      </div>
      <div className="tw-text-xxs tw-row-start-1 tw-col-start-1 tw-flex tw-items-end tw-justify-end tw-leading-none tw-pointer-events-none">
        <div
          className={cn(
            'tw-pb-[2px] tw-pe-[4px] tw-rounded-sm tw-font-bold tw-py-[0.5px]',
            maxLengthPercentage === 100
              ? 'tw-text-horny-red-500'
              : 'tw-text-horny-orange-700 tw-opacity-50'
          )}
        >
          {currentLength}
          {' / '}
          {maxLength}
        </div>
      </div>
    </div>
  );
}
