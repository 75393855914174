import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChatMessageMedia,
  ChatMessage as ChatMessageType,
  DeleteMessagesAfterMessageIdRequest,
  GetChatResponse,
  MediaType,
  MessageAddAudioRequest,
  MessageSenderEnum,
  MessageStatusEnum,
  MessageTypeEnum,
  RefetchMessagesRequest,
  RequestPhotoRequest,
} from 'src/@types/common';
import 'src/components/Shared/tw-base.css';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { ChatAPI, RegenerateImageRequstV2 } from 'src/services/API/ChatAPI';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
import { SubscriptionLevel } from 'src/services/API/ResourcesAPI';
import { UserAPI } from 'src/services/API/UserAPI';
import useInterval from 'src/services/Hooks/UseInterval';
import { GetAxiosError } from 'src/services/Utils/GetAxiosError';
import { WaitForImages } from 'src/services/Utils/WaitForImages';
import { ifPaymentError } from 'src/services/axios/axios';
import { showToast } from '../Shared/Ark/ArkToast';
import {
  ShowSubscriptionsReason,
  Subscriptions,
  TabType,
} from '../Subscription/Subscriptions';
import { ChatMessage } from './ChatMessage/ChatMessage';
import { Interaction } from './Interaction/Interaction';
import getMessageMediaType from './MessageMediaType';
import useOnScreen from './useOnScreen';

export type handleRequestPhotoProps = Omit<RequestPhotoRequest, 'BotId'>;

export enum ChatStatus {
  Init = 'init',
  Idle = 'idle',
  Waiting = 'waiting',
  WaitingText = 'waitingText',
  Loading = 'loading',
}

export enum InteractionType {
  Text = 'text',
  Media = 'media',
}

type MessageRefs = {
  [key: string]: HTMLDivElement | null;
};

const nonUnlimMessagesSubs = [SubscriptionLevel.Friend];
const nonUnlimEnergySubs = [
  SubscriptionLevel.Friend,
  SubscriptionLevel.Boyfriend,
  SubscriptionLevel.Daddy,
];

export type ChatProps = {
  chatResponse: GetChatResponse;
  isRestartFromMessage: boolean;
  disableRestartFrom: () => any;
  isOtherSettingsFetching: boolean;
};

export function Chat({
  chatResponse,
  isRestartFromMessage,
  disableRestartFrom,
  isOtherSettingsFetching,
}: ChatProps) {
  const { t } = useTranslation();
  const { user, setUser } = useUserContext();
  const { addModal } = useModalContext();
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const premiumModal = (
    reason: ShowSubscriptionsReason = ShowSubscriptionsReason.Messages,
    defaultTab: TabType = 'subscription'
  ) => {
    const modal = addModal({
      children: (
        <Subscriptions
          showReason={reason}
          source="chat_settings"
          defaultTab={defaultTab}
        />
      ),
      showSubscriptionButton: false,
    });
  };

  const [isVoiceDisabled, setIsVoiceDisabled] = useState(false);

  const [disabledMedia, setDisabledMedia] = useState(false);
  const [refetchIds, setRefetchIds] = useState<string[]>(
    chatResponse.messages
      .filter((m) => m.MessageStatus === MessageStatusEnum.preparing)
      .map((m) => m.Id)
  );
  const removeRefetchId = (id: string) => {
    setRefetchIds((prev) => prev.filter((m) => m !== id));
  };
  const addRefetchId = (id: string) => {
    setRefetchIds((prev) => [...prev, id]);
  };
  const { capture } = useAnalyticsContext();
  const { prices } = useResourcesContext();
  const [messages, setMessages] = useState<ChatMessageType[]>(
    chatResponse.messages
  );

  const [refetchReplySignal, setRefetchReplySignal] = useState<
    number | undefined
  >(undefined);

  const handleRefetchReply = () => {
    setRefetchReplySignal((prev) => (prev === undefined ? 1 : prev + 1));
  };

  const [disabledWriting, setDisabledWriting] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const messageRefs = useRef<MessageRefs>({});
  const interactionRef = useRef(null);
  const [interactionHeight, setInteractionHeight] = useState(0);
  const [lastMessageRef, setLastMessageRef] = useState(null);
  const [total, setTotal] = useState(chatResponse.Total);

  const isIntersecting = useOnScreen({ current: lastMessageRef });

  const moreMessagesMutations = useMutation({
    mutationFn: () => {
      return ConnectionsAPI.getMessages({
        StartFrom: (messages || []).length,
        PageSize: 40,
        BotId: chatResponse.chat.BotId,
      });
    },
    onSuccess: (data) => {
      setTotal(data.data.Total);
      setMessages((oldMessages) => {
        return [...data.data.Messages, ...oldMessages];
      });
      setRefetchIds([
        ...refetchIds,
        ...data.data.Messages.filter(
          (m) => m.MessageStatus === MessageStatusEnum.preparing
        ).map((m) => m.Id),
      ]);
    },
  });

  const handleMoreMessages = () => {
    moreMessagesMutations.mutate();
  };

  const scrollChatToBottom = (delay: number = 0) => {
    const container = containerRef.current as unknown as HTMLElement;
    setTimeout(() => {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth',
      });
    }, delay);
  };

  async function scrollToMessage(messageId: string) {
    const element = messageRefs.current[messageId];

    const container = containerRef.current;

    if (!element || !container) return;

    // Delay to offset animation (?)
    await new Promise((res) => setTimeout(res, 300));

    await WaitForImages(element);

    // сколько от верха элемента до верха контейнера
    const elementOffsetTop = element.offsetTop;

    // какое значение padding-top и padding-bottom контейнера
    const containerPaddingTop = parseFloat(
      window.getComputedStyle(container).paddingTop
    );
    const containerPaddingBottom = parseFloat(
      window.getComputedStyle(container).paddingBottom
    );

    // высоты контейнера (это реальная видимая высота контейнера)
    const containerHeight =
      container.clientHeight - containerPaddingBottom - containerPaddingTop;

    // высота элемента
    const elementHeight = element.getBoundingClientRect().height;

    // высота области, которая скроллится
    const containerScrollHeight = container.scrollHeight;

    // Scroll область без padding
    const containerScrollHeightWithoutPadding =
      containerScrollHeight - containerPaddingBottom - containerPaddingTop;

    // какое значение scrollTo должно быть чтобы элемент был в середине области контейнера
    const scrollToMiddleScreen =
      elementOffsetTop -
      containerPaddingTop -
      (containerHeight - elementHeight) / 2;

    // console.log('SCRL', {
    //   elementOffsetTop: elementOffsetTop,
    //   containerPaddingTop: containerPaddingTop,
    //   containerPaddingBottom: containerPaddingBottom,
    //   containerHeight: containerHeight,
    //   elementHeight: elementHeight,
    //   containerScrollHeight: containerScrollHeight,
    //   containerScrollHeightWithoutPadding: containerScrollHeightWithoutPadding,
    //   scrollToMiddleScreen: scrollToMiddleScreen,
    // });

    let scrollTo = 0;

    if (containerHeight < elementHeight) {
      scrollTo = elementOffsetTop - containerPaddingTop - 20;
    } else if (
      elementOffsetTop +
        elementHeight +
        (containerHeight - elementHeight) / 2 +
        containerPaddingBottom <
      containerScrollHeight
    ) {
      scrollTo = scrollToMiddleScreen;
    } else {
      scrollTo = containerScrollHeight;
    }

    container.scrollTo({
      top: scrollTo,
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    initialLoadComplete &&
      !moreMessagesMutations.isPending &&
      handleMoreMessages();
  }, [isIntersecting]);

  // - -  GET BOT RESPONSE - -
  const generateBotTextMutation = useMutation({
    mutationFn: (data: { text: string }) =>
      ChatAPI.botText({ BotId: chatResponse.chat.BotId, Text: data.text }),
    onMutate: (data) => {
      setUser({ ...user!, MessagesLeft: user!.MessagesLeft - 1 });
      setDisabledWriting(true);
      setMessages((oldMessages) => {
        const timestamp = new Date();
        const userTempMessage: ChatMessageType = {
          Id: 'temp_user_message',
          MessageStatus: MessageStatusEnum.ready,
          MessageSender: MessageSenderEnum.User,
          Text: data.text,
          Media: null,
          SkipLLM: false,
          MessageType: MessageTypeEnum.Normal,
          Timestamp: timestamp,
        };
        const botTempMessage: ChatMessageType = {
          Id: 'temp_bot_message',
          MessageStatus: MessageStatusEnum.preparing,
          MessageSender: MessageSenderEnum.Bot,
          Text: null,
          Media: null,
          SkipLLM: false,
          MessageType: MessageTypeEnum.Normal,
          Timestamp: timestamp,
        };
        return [...oldMessages, userTempMessage, botTempMessage];
      });
      scrollChatToBottom();
    },
    onSuccess: (data) => {
      setDisabledWriting(false);
      setMessages((oldMessages) => {
        const filteredMessages = oldMessages.filter(
          (msg) =>
            msg.Id !== 'temp_user_message' && msg.Id !== 'temp_bot_message'
        );
        return [...filteredMessages, ...data.data.Messages];
      });
      UserAPI.get().then(({ data }) => {
        setUser(data);
      });
      handleRefetchReply();
      setTimeout(() => scrollToMessage(data.data.Messages[1].Id), 100);
      capture({
        event: 'message_sent',
        data: {
          request_type: 'generate',
          is_own_bot: chatResponse.chat.IsBotAuthor,
          is_suggested: false,
          bot_id: chatResponse.chat.BotId,
          tokens_total: data.data.Tokens?.total_tokens
            ? data.data.Tokens.total_tokens
            : null,
          tokens_completion: data.data.Tokens?.completion_tokens
            ? data.data.Tokens.completion_tokens
            : null,
          tokens_prompt: data.data.Tokens?.prompt_tokens
            ? data.data.Tokens.prompt_tokens
            : null,
          llm_model: user?.LLMModel ? user.LLMModel : null,
          is_moderated: data.data.Messages[0].SkipLLM,
        },
      });
    },
    onError: (response) => {
      setUser({ ...user!, MessagesLeft: user!.MessagesLeft + 1 });
      setMessages((oldMessages) => {
        const filteredMessages = oldMessages.filter(
          (msg) =>
            msg.Id !== 'temp_user_message' && msg.Id !== 'temp_bot_message'
        );
        return [...filteredMessages];
      });
      setDisabledWriting(false);
      if (ifPaymentError(response)) {
        if (
          user?.MergedSubscription.Level &&
          !nonUnlimMessagesSubs.includes(user?.MergedSubscription.Level)
        ) {
          showToast({
            title: t('Error generating message'),
            description: t('An error occured while generating reply'),
            support: true,
          });
        } else {
          premiumModal();
        }
      } else {
        showToast({
          title: t('Error generating message'),
          description: t('An error occured while generating reply'),
          support: true,
        });
      }
      UserAPI.get().then(({ data }) => {
        setUser(data);
      });
      scrollChatToBottom();
    },
  });

  const handleSendMessage = (text: string) => {
    if (user?.MessagesLeft! < 1) {
      if (
        user?.MergedSubscription.Level &&
        !nonUnlimMessagesSubs.includes(user?.MergedSubscription.Level)
      ) {
        showToast({
          title: t('Error generating message'),
          description: t('An error occured while generating reply'),
          support: true,
        });
      } else {
        premiumModal();
      }
      return;
    }
    generateBotTextMutation.mutate({ text: text });
  };
  // - -  /GET BOT RESPONSE - -

  // - -  REGENERATE BOT RESPONSE - -
  const regenerateBotTextMutation = useMutation({
    mutationFn: (data: { messageId: string }) =>
      ChatAPI.regenerateBotText({
        BotId: chatResponse.chat.BotId,
        MessageId: data.messageId,
      }),
    onMutate: (data) => {
      setUser({ ...user!, MessagesLeft: user!.MessagesLeft - 1 });
      setDisabledWriting(true);
      setMessages((oldMessages) =>
        oldMessages.map((m) => {
          if (m.Id === data.messageId) {
            return {
              ...m,
              MessageStatus: MessageStatusEnum.preparing,
              Media: null,
            };
          }
          return m;
        })
      );
    },
    onSuccess: (data) => {
      setDisabledWriting(false);
      setMessages((oldMessages) =>
        oldMessages.map((m) => {
          if (m.Id === data.data.Message.Id) {
            return data.data.Message;
          }
          return m;
        })
      );
      UserAPI.get().then(({ data }) => {
        setUser(data);
      });
      handleRefetchReply();
      scrollToMessage(data.data.Message.Id);
      // capture({
      //   event: 'message_sent',
      //   data: {
      //     request_type: 'regenerate',
      //     is_own_bot: chatResponse.chat.IsBotAuthor,
      //     is_suggested: false,
      //     bot_id: chatResponse.chat.BotId,
      //     tokens_total: data.data.Tokens?.total_tokens
      //       ? data.data.Tokens.total_tokens
      //       : null,
      //     tokens_completion: data.data.Tokens?.completion_tokens
      //       ? data.data.Tokens.completion_tokens
      //       : null,
      //     tokens_prompt: data.data.Tokens?.prompt_tokens
      //       ? data.data.Tokens.prompt_tokens
      //       : null,
      //     llm_model: user?.LLMModel ? user.LLMModel : null,
      //   },
      // });
    },
    onError: (response, variables) => {
      setUser({ ...user!, MessagesLeft: user!.MessagesLeft + 1 });
      setMessages((oldMessages) =>
        oldMessages.map((m) => {
          if (m.Id === variables.messageId) {
            return { ...m, MessageStatus: MessageStatusEnum.ready };
          }
          return m;
        })
      );
      setDisabledWriting(false);
      if (ifPaymentError(response)) {
        if (
          user?.MergedSubscription.Level &&
          !nonUnlimMessagesSubs.includes(user?.MergedSubscription.Level)
        ) {
          showToast({
            title: t('Error regenerating message'),
            description: t('An error occured while regenerating reply'),
            support: true,
          });
        } else {
          premiumModal();
        }
      } else {
        showToast({
          title: t('Error regenerating message'),
          description: t('An error occured while regenerating reply'),
          support: true,
        });
      }
      UserAPI.get().then(({ data }) => {
        setUser(data);
      });
    },
  });

  const handleTextRegenerate = (messageId: string) => {
    if (user?.MessagesLeft! < 1) {
      if (
        user?.MergedSubscription.Level &&
        !nonUnlimMessagesSubs.includes(user?.MergedSubscription.Level)
      ) {
        showToast({
          title: t('Error regenerating message'),
          description: t('An error occured while regenerating reply'),
          support: true,
        });
      } else {
        premiumModal();
      }
      return;
    }
    regenerateBotTextMutation.mutate({ messageId: messageId });
  };
  // - -  /REGENERATE BOT RESPONSE - -

  useEffect(() => {
    const observer = new ResizeObserver(function (entries) {
      let rect = entries[0].contentRect;
      setInteractionHeight(rect.height);
      // scrollChatToBottom();
    });

    observer.observe(interactionRef.current!);

    return () => {
      if (interactionRef.current) {
        observer.unobserve(interactionRef.current);
      } else {
        observer.disconnect();
      }
    };
  }, []);

  const lastIndexToRegenerateText = [...messages].reverse().findIndex((m) => {
    const messageMediaType = getMessageMediaType(m);
    return (
      m.MessageSender === MessageSenderEnum.Bot &&
      m.MessageType === MessageTypeEnum.Normal &&
      messageMediaType !== MediaType.Photo &&
      !m.SkipLLM
      // &&
      // m.MessageStatus === MessageStatusEnum.ready
    );
  });

  const requestPhotoMutation = useMutation({
    mutationFn: (data: RequestPhotoRequest) => ChatAPI.requestPhoto(data),
    // onMutate: () => {
    //   setDisabledMedia(true);
    //   setMessages((oldMessages) => {
    //     const timestamp = new Date();
    //     const mediaTempMessage: ChatMessageType = {
    //       Id: 'temp_media_message',
    //       MessageStatus: MessageStatusEnum.preparing,
    //       MessageSender: MessageSenderEnum.Bot,
    //       Text: null,
    //       Media: null,
    //       SkipLLM: false,
    //       MessageType: MessageTypeEnum.Normal,
    //       Timestamp: timestamp,
    //       MediaGenerationInfo: {
    //         NudityLevel: NudityLevel.Nude,
    //         Place: 'none',
    //         PromptId: 'none',
    //       },
    //     };
    //     return [...oldMessages, mediaTempMessage];
    //   });
    //   scrollChatToBottom();
    // },
    onSuccess: (data) => {
      setMessages((oldMessages) => [...oldMessages, data.data.Message]);
      addRefetchId(data.data.Message.Id);
      capture({
        event: 'media_request',
        data: {
          request_type: 'generate',
          is_own_bot: chatResponse.chat.IsBotAuthor,
          is_suggested: false,
          bot_id: chatResponse.chat.BotId,
          media_type: 'image',
        },
      });
      capture({
        event: 'currency_spent',
        data: {
          amount: prices?.Media.Photo!,
          spent_on: 'image',
        },
      });
      UserAPI.get().then(({ data }) => {
        setUser(data);
      });
      scrollChatToBottom();
    },
    onError: (error) => {
      // setMessages((oldMessages) => {
      //   const filteredMessages = oldMessages.filter(
      //     (msg) => msg.Id !== 'temp_media_message'
      //   );
      //   return [...filteredMessages];
      // });
      if (ifPaymentError(error)) {
        if (
          user?.MergedSubscription.Level &&
          !nonUnlimEnergySubs.includes(user?.MergedSubscription.Level)
        ) {
          showToast({
            title: t('Error generating image'),
            description: t('An error occured while generating image'),
            support: true,
          });
        } else {
          premiumModal(ShowSubscriptionsReason.Energy);
        }
      } else {
        showToast({
          title: t('Error generating image'),
          description: t('An error occured while generating image'),
          support: true,
        });
      }
      setDisabledMedia(false);
      UserAPI.get().then(({ data }) => {
        setUser(data);
      });
    },
  });

  const handleRequestPhoto = (props: handleRequestPhotoProps) => {
    if (user?.Energy! < prices?.Media.Photo!) {
      if (
        user?.MergedSubscription.Level &&
        !nonUnlimEnergySubs.includes(user?.MergedSubscription.Level)
      ) {
        showToast({
          title: t('Error generating photo'),
          description: t('An error occured while generating photo'),
          support: true,
        });
      } else {
        premiumModal(ShowSubscriptionsReason.Energy);
      }
      return;
    }

    if (!props.CustomPrompt && !props.PromptId) {
      showToast({
        title: t('Error generating photo'),
        description: t('An error occured while generating photo'),
        support: true,
      });

      return;
    }

    setDisabledMedia(true);

    if (props.Type === 'predefined') {
      requestPhotoMutation.mutate({
        BotId: chatResponse.chat.BotId,
        PromptId: props.PromptId,
        NudityLevel: props.NudityLevel,
        Type: props.Type,
      });
    } else {
      requestPhotoMutation.mutate({
        BotId: chatResponse.chat.BotId,
        CustomPrompt: props.CustomPrompt,
        NudityLevel: props.NudityLevel,
        Type: props.Type,
      });
    }
  };

  const regeneratePhotoMutation = useMutation({
    mutationFn: (data: RegenerateImageRequstV2) =>
      ChatAPI.regeneratePhoto(data),
    onMutate: (data) => {
      setMessages((oldMessages) =>
        oldMessages.map((m) => {
          if (m.Id === data.MessageId) {
            return {
              ...m,
              MessageStatus: MessageStatusEnum.preparing,
            };
          }
          return m;
        })
      );
    },
    onSuccess: (data) => {
      setMessages((oldMessages) =>
        oldMessages.map((m) => {
          if (m.Id === data.data.Message.Id) {
            return data.data.Message;
          }
          return m;
        })
      );
      addRefetchId(data.data.Message.Id);
      capture({
        event: 'media_request',
        data: {
          request_type: 'regenerate',
          is_own_bot: chatResponse.chat.IsBotAuthor,
          is_suggested: false,
          bot_id: chatResponse.chat.BotId,
          media_type: 'image',
        },
      });

      capture({
        event: 'currency_spent',
        data: {
          amount: prices?.Media.Photo! / 2,
          spent_on: 'image',
          regenerate: true,
        },
      });
      UserAPI.get().then(({ data }) => {
        setUser(data);
      });
    },
    onError: (error, context) => {
      setDisabledMedia(false);
      setMessages((oldMessages) =>
        oldMessages.map((m) => {
          if (m.Id === context.MessageId) {
            return {
              ...m,
              MessageStatus: MessageStatusEnum.ready,
            };
          }
          return m;
        })
      );

      if (ifPaymentError(error)) {
        if (
          user?.MergedSubscription.Level &&
          !nonUnlimEnergySubs.includes(user?.MergedSubscription.Level)
        ) {
          showToast({
            title: t('Error generating image'),
            description: t('An error occured while generating image'),
            support: true,
          });
        } else {
          premiumModal(ShowSubscriptionsReason.Energy);
        }
      } else {
        showToast({
          title: t('Error generating image'),
          description: t('An error occured while generating image'),
          support: true,
        });
      }

      UserAPI.get().then(({ data }) => {
        setUser(data);
      });
    },
  });

  const handleRegeneratePhoto = (messageId: string) => {
    if (user?.Energy! < prices?.Media.Photo! / 2) {
      if (
        user?.MergedSubscription.Level &&
        !nonUnlimEnergySubs.includes(user?.MergedSubscription.Level)
      ) {
        showToast({
          title: t('Error generating photo'),
          description: t('An error occured while generating photo'),
          support: true,
        });
      } else {
        premiumModal(ShowSubscriptionsReason.Energy);
      }
      return;
    }
    setDisabledMedia(true);
    regeneratePhotoMutation.mutate({
      BotId: chatResponse.chat.BotId,
      MessageId: messageId,
    });
  };

  const refetchMediaMutation = useMutation({
    mutationFn: (data: RefetchMessagesRequest) => ChatAPI.refetchMessages(data),
    onSuccess: (data) => {
      setMessages((oldMessages) =>
        oldMessages.map((oldMessage) => {
          const updatedMessage = data.data.Messages.find(
            (newMessage) => newMessage.Id === oldMessage.Id
          );

          if (
            updatedMessage &&
            updatedMessage.MessageStatus === MessageStatusEnum.ready
          ) {
            removeRefetchId(updatedMessage.Id);
            return updatedMessage;
          }
          return oldMessage;
        })
      );

      data.data.Messages.find(
        (m) => m.MessageStatus === MessageStatusEnum.ready
      )?.Id &&
        UserAPI.get().then(({ data }) => {
          setUser(data);
        });
      const updatedMessagesIds = data.data.Messages.map((m) => {
        if (m.MessageStatus === MessageStatusEnum.ready) {
          return m.Id;
        }
        return null;
      }).filter((id): id is string => id !== null);
      if (updatedMessagesIds && updatedMessagesIds.length > 0) {
        scrollToMessage(updatedMessagesIds[updatedMessagesIds.length - 1]);
      }
    },
  });

  const handleRestartFromMessage = (messageId: string) => {
    restartFromMessageMutation.mutate({
      MessageId: messageId,
    });
  };

  // MARK: AUDIO MUTATION
  type AddMessageAudioMutationData = MessageAddAudioRequest & {
    audioGeneratePrice: number;
  };

  const addMessageAudioMutation = useMutation({
    mutationFn: (data: AddMessageAudioMutationData) => {
      const { audioGeneratePrice, ...requestData } = data;
      return ChatAPI.messageAddAudio(requestData);
    },
    onMutate: (data) => {
      setIsVoiceDisabled(true);
      setMessages((oldMessages) =>
        oldMessages.map((m) => {
          const optimisticMedia: ChatMessageMedia = {
            MediaId: 'temp_audio_message',
            MediaType: MediaType.Audio,
            PreviewUrl: 'temp_audio_message',
            Url: 'temp_audio_message',
          };
          if (m.Id === data.MessageId) {
            return {
              ...m,
              MessageStatus: MessageStatusEnum.preparing,
              Media: [optimisticMedia],
            };
          }
          return m;
        })
      );
    },
    onSuccess: (data, variables) => {
      setMessages((oldMessages) =>
        oldMessages.map((m) => {
          if (m.Id === data.data.Id) {
            return data.data;
          }
          return m;
        })
      );
      UserAPI.get().then(({ data }) => {
        setUser(data);
      });
      capture({
        event: 'media_request',
        data: {
          request_type: 'generate',
          is_own_bot: chatResponse.chat.IsBotAuthor,
          is_suggested: false,
          bot_id: chatResponse.chat.BotId,
          media_type: 'audio',
          audio_type: 'add',
        },
      });

      capture({
        event: 'credits_spent',
        data: {
          amount: variables.audioGeneratePrice,
          spent_on: 'audio_add',
          regenerate: false,
        },
      });
      setIsVoiceDisabled(false);
    },
    onError: (error, variables) => {
      setIsVoiceDisabled(false);
      UserAPI.get().then(({ data }) => {
        setUser(data);
      });
      setMessages((oldMessages) =>
        oldMessages.map((m) => {
          if (m.Id === variables.MessageId) {
            return {
              ...m,
              MessageStatus: MessageStatusEnum.ready,
              Media: null,
            };
          }
          return m;
        })
      );
      if (ifPaymentError(error)) {
        premiumModal(ShowSubscriptionsReason.Credits, 'credits');
      } else {
        showToast({
          title: t('Error adding audio'),
          description: GetAxiosError(
            error as AxiosError,
            t('An error occured while adding audio')
          ),
          support: true,
        });
      }
    },
  });

  const restartFromMessageMutation = useMutation({
    mutationFn: (data: DeleteMessagesAfterMessageIdRequest) =>
      ChatAPI.restartFromMessage(data),
    onMutate: (data) => {
      setDisabledMedia(true);
      setDisabledWriting(true);
      const previousMessages = messages;
      setMessages((oldMessages) => {
        const referenceMessage = oldMessages.find(
          (m) => m.Id === data.MessageId
        );
        if (referenceMessage) {
          return oldMessages.filter(
            (m) => m.Timestamp <= referenceMessage.Timestamp
          );
        }
        return oldMessages;
      });
      return { previousMessages };
    },
    onError: (error, variables, context) => {
      if (context) {
        setMessages(context.previousMessages);
      }
      showToast({
        title: t('Error restarting from message'),
        description: t('An error occured while restarting from message'),
        support: true,
      });
    },
    onSuccess: () => {
      setDisabledMedia(false);
      setDisabledWriting(false);
      disableRestartFrom();
      setRefetchReplySignal((prev) => (prev ? prev + 1 : 1));
    },
  });

  useInterval(() => {
    if (refetchIds.length > 0) {
      refetchMediaMutation.mutate({ MessageIds: refetchIds });
    }
  }, 5000);

  useEffect(() => {
    if (refetchIds.length > 0) {
      setDisabledMedia(true);
      refetchMediaMutation.mutate({ MessageIds: refetchIds });
    } else {
      setDisabledMedia(false);
    }
  }, [refetchIds]);

  useEffect(() => {
    const initializeChat = async () => {
      if (containerRef.current) {
        await WaitForImages(containerRef.current);
        scrollChatToBottom();
        // timeot
        await new Promise((res) => setTimeout(res, 2000));
        setInitialLoadComplete(true);
      }
    };
    initializeChat();
  }, []);

  // Hidden bottom height (from opacity)
  const fadeOutHeight = 20;

  return (
    <div className="tw-relative tw-h-full">
      <div
        className="tw-relative px-2 tw-scrollbar-hide tw-px-4 tw-overflow-y-scroll tw-flex tw-flex-col tw-gap-5 tw-h-full tw-pt-32 tw-box-border" //chat
        style={{
          paddingBottom: interactionHeight + 10,
          maskImage: `linear-gradient(to bottom, black calc(100% - ${interactionHeight + fadeOutHeight}px), transparent calc(100% - ${interactionHeight - fadeOutHeight * 2}px), transparent 100%)`,
          WebkitMaskImage: `linear-gradient(to bottom, black calc(100% - ${interactionHeight + fadeOutHeight}px), transparent calc(100% - ${interactionHeight - fadeOutHeight * 2}px), transparent 100%)`,
        }}
        ref={containerRef}
      >
        <AnimatePresence>
          {moreMessagesMutations.isPending && (
            <div className="tw-animate-pulse tw-w-full tw-flex tw-flex-col tw-items-center">
              <div className="tw-bg-horny-gray-100 tw-w-3/4 tw-h-6 tw-rounded tw-opacity-60"></div>
            </div>
          )}
          {[...messages].map((m, i) => {
            const messageMediaType =
              m.Media && m.Media.length > 0 ? m.Media[0].MediaType : undefined;
            return (
              <motion.div
                key={m.Id}
                layout
                transition={{ layout: { duration: 0.2 } }}
                ref={(el) => (messageRefs.current[m.Id] = el)}
              >
                <div
                  ref={(ref) =>
                    i === 5 && messages.length < total
                      ? setLastMessageRef(ref as SetStateAction<any>)
                      : null
                  }
                >
                  <ChatMessage
                    message={m}
                    {...m}
                    model={chatResponse.chat.ImageGenerationModel}
                    mediaChoiseDisabled={disabledMedia}
                    canRegenerate={
                      (!disabledWriting &&
                        i ===
                          messages.length - 1 - lastIndexToRegenerateText) ||
                      (messageMediaType === MediaType.Photo && !disabledMedia)
                    }
                    requestRandomMedia={(data) => {
                      handleRequestPhoto(data);
                    }}
                    IsBotAuthor={chatResponse.chat.IsBotAuthor}
                    onMediaRequest={() => {}}
                    onRegenerate={() => {
                      const { MediaGenerationInfo } = m;
                      if (MediaGenerationInfo) {
                        handleRegeneratePhoto(m.Id);
                      } else {
                        handleTextRegenerate(m.Id);
                      }
                    }}
                    botId={chatResponse.chat.BotId}
                    onRestartFrom={(messageId) => {
                      handleRestartFromMessage(messageId);
                    }}
                    isRestartFrom={
                      isRestartFromMessage && i !== messages.length - 1
                    }
                    onAddMessageAudio={(messageId, audioGeneratePrice) => {
                      setUser({
                        ...user!,
                        Credits: user?.Credits! - audioGeneratePrice,
                      });
                      addMessageAudioMutation.mutate({
                        MessageId: messageId,
                        BotId: chatResponse.chat.BotId,
                        audioGeneratePrice, // Добавляем этот параметр
                      });
                    }}
                    isOtherSettingsFetching={isOtherSettingsFetching}
                    isVoiceDisabled={isVoiceDisabled}
                  />
                </div>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
      <div
        className="tw-absolute tw-bottom-0 tw-w-full tw-max-w-[600px] tw-bg-gradient-to-b tw-from-transparent tw-to-black"
        ref={interactionRef}
      >
        {messages && (
          <Interaction
            model={chatResponse.chat.ImageGenerationModel}
            BotId={chatResponse.chat.BotId}
            Name={chatResponse.chat.Name}
            disabledWriting={disabledWriting}
            disableMedia={disabledMedia}
            Author={chatResponse.chat.Author}
            requestTextMessage={(text) => {
              handleSendMessage(text);
            }}
            requestMedia={(data) => {
              handleRequestPhoto(data);
            }}
            IsBotAuthor={chatResponse.chat.IsBotAuthor}
            refetchReplySignal={refetchReplySignal}
          />
        )}
      </div>
    </div>
  );
}
